import { gql } from "@apollo/client";

export const CreateAdminRequest = gql`
  mutation createAdminAccount(
    $email: String
    $password: String
    $user_name: String
    $full_name: String
    $role: String
    $phone_number: String
  ) {
    createAdminAccount(
      email: $email
      password: $password
      user_name: $user_name
      full_name: $full_name
      role: $role
      phone_number: $phone_number
    )
  }
`;

export const UpdatePasswordRequest = gql`
  mutation updatePassword($user_id: String, $password: String) {
    updatePassword(user_id: $user_id, password: $password)
  }
`;

export const UpdateProfileRequest = gql`
  mutation updateProfile(
    $email: String
    $user_name: String
    $user_id: String
    $full_name: String
    $role: String
    $phone_number: String
  ) {
    updateProfile(
      email: $email
      user_name: $user_name
      user_id: $user_id
      full_name: $full_name
      role: $role
      phone_number: $phone_number
    )
  }
`;

export const UpdateProfilePictureRequest = gql`
  mutation updateProfilePicture($user_id: String, $file: Upload!) {
    updateProfilePicture(user_id: $user_id, file: $file)
  }
`;
