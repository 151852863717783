import React from "react";
import { Footer } from "./Footer";
import { Navbar } from "./Navbar/navbar";
import { Sidebar } from "./Sidebar/Sidebar";

export const PageSetup = (props) => {
  return (
    <div>
      <Sidebar />
      <Navbar title={props.title} />
      <section className="body-with-nav">
        <div className="container my-5">{props.children}</div>
        <div className="mt-auto">
          <Footer />
        </div>
      </section>
    </div>
  );
};
