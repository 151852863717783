import React from "react";
import * as V from "victory";

export const PieChart = ({ chartData }) => {
  const newData = [];
  Object.entries(chartData).forEach((value) => {
    if (value[1] > 0) {
      newData.push({
        x: `${value[0]} ${value[1]}%`,
        y: value[1],
      });
    }
  });
  return (
    <div>
      <svg viewBox="0 0 400 400">
        <V.VictoryPie
          standalone={false}
          // width={400}
          // height={400}
          data={newData}
          innerRadius={68}
          colorScale={["navy", "orange", "gold", "cyan", "tomato"]}
          style={{
            labels: {
              fontSize: 18,
              fontWeight: "bold",
              textTransform: "capitalize",
              fill: "red",
            },
          }}
          labels={({ datum }) => datum.x}
          labelComponent={<V.VictoryLabel angle={-60} />}
          cornerRadius={10}
          labelRadius={100}
        />
        <V.VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 20 }}
          x={200}
          y={200}
          // text="Pie!"
        />
      </svg>
    </div>
  );
};
