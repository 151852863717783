import React from "react";

export const PrivacyPolicy = () => {
  return (
    <div className="container mt-5">
      <p>
        <h4>PRIVACY POLICY</h4>
      </p>
      <p>1. OUR COMMITMENT TO YOUR PRIVACY</p>
      <p>
        This Privacy Policy tells you what personally identifiable information
        we may collect from you, how we may process your personally identifiable
        information, how you can limit our use of your personally identifiable
        information, and your rights to obtain, modify and/or delete any
        personally identifiable information we have collected from you. &nbsp;
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>2. INFORMATION WE COLLECT</p>
      <p>
        <strong>
          2.1 Information you provide FroClub
          <span class="Apple-converted-space">&nbsp;</span>
        </strong>
      </p>
      <p>
        We collect personal information when you request information about our
        services or otherwise voluntarily provide such information through our
        site. <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        Generally, you will have control over the amount and type of information
        you provide to us when using our site.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        As a visitor you can browse our site to find out more.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>2.2 Information Collected Automatically</strong>
      </p>
      <p>
        When you use the FroClub site, we automatically collect certain
        information by the interaction of your mobile device or web browser with
        our platform.<span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>2.3 Cookies</strong>
      </p>
      <p>
        Like many other sites, we use &ldquo;Cookies.&rdquo; A Cookie is a small
        piece of data stored on your computer or mobile device by our site. We
        use Cookies to identify the areas of our site that you have visit. We
        also use cookies to enhance your online experience by eliminating the
        need to log in multiple times for specific content. Finally, we may use
        Cookies to personalize the content that you see on our site or to
        customize marketing and other information we provide to you.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>2.4 Other Automatically-Gathered Information</strong>
      </p>
      <p>
        FroClub may automatically record information when you visit its site,
        including the URL, IP address, browser type and language, and the date
        and time of your visit. FroClub uses this information to analyze trends
        among its users to help improve its site or customize communications and
        information that you receive from us. If combined with other information
        we know about you from previous visits, the data possibly could be used
        to identify you personally, even if you are not signed in to our site.
      </p>
      <p>&nbsp;</p>
      <p>3. HOW INFORMATION IS USED</p>
      <p>
        When FroClub uses or processes personal data about you, it does so only
        as necessary to provide the services you use or otherwise with your
        consent, to comply with applicable law, or to fulfill other legitimate
        interests of you or us as described in this Policy. &nbsp;Through our
        site, you will be provided with the choice of which types of
        communications you will receive with us, and the ability to change those
        choices whenever you want.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>3.1Information we process with your consent</strong>
      </p>
      <p>
        Through certain actions when otherwise there is no contractual
        relationship between us, such as when you browse our site or ask us to
        provide you more information about our business, you provide your
        consent to us to process information that may be personally identifiable
        information.<span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        Wherever possible, we aim to obtain your explicit consent to process
        this information, for example, by asking you to agree to our use of
        Cookies or to receive communications from us.
      </p>
      <p>&nbsp;</p>
      <p>
        FroClub continues to process your information on this basis until you
        withdraw your consent or it can be reasonably assumed that your consent
        no longer exists.&nbsp;&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        You may withdraw your consent at any time by instructing us using the
        contact information at the end of this Policy. However, if you do so,
        you may not be able to use our site further.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>3.2 Legally Required Releases of Information</strong>
      </p>
      <p>
        FroClub may be legally required to disclose your personally identifiable
        information, if such disclosure is (a) required by law, or other legal
        process; (b) necessary to assist law enforcement officials or government
        enforcement agencies; (c) necessary to investigate violations of or
        otherwise enforce our Legal Terms; (d) necessary to protect us from
        legal action or claims from third parties including you; and/or (e)
        necessary to protect the legal rights, personal/real property, or
        personal safety of our company, clients, third party partners,
        employees, and affiliates.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>4. OBTAINING, CHANGING OR DELETING YOUR INFORMATION</p>
      <p>
        <strong>4.1 Access to your personal information</strong>
      </p>
      <p>
        To obtain a copy of all information we maintain about you, you may send
        us a request using the contact information at the end of this Policy or,
        if available, through a tool on our site. &nbsp;After receiving the
        request, we will tell you when we expect to provide you with the
        information, and whether we require any fee for providing it to you.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>4.2 Remove or Change your Information</strong>
      </p>
      <p>
        If you wish us to remove or change personally identifiable information
        that you have provided us, you may contact us at the contact information
        at the end of this Policy or if available through a tool on our site.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>4.3 Verification of your Information</strong>
      </p>
      <p>
        When we receive any request to access, edit or delete personally
        identifiable information, we will first take reasonable steps to verify
        your identity before granting you access or otherwise taking any action.
        This is important to safeguard your information.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5. RETENTION PERIOD FOR PERSONAL DATA</strong>
      </p>
      <p>
        Except as otherwise mentioned in this Policy, we keep your personally
        identifiable information only for as long as required:
      </p>
      <ul>
        <li>
          to provide you with the services you have requested, or otherwise to
          perform or enforce a contract between us;
        </li>
        <li>
          to continue to provide the best possible user experience to visitors
          who return to our site to collect information;
        </li>
        <li>
          to comply with other law, including for any period demanded by tax
          authorities; or
        </li>
        <li>
          to support a claim or defense in any court or in any legal, regulatory
          or administrative proceeding.
        </li>
      </ul>
      <p>
        <strong>6. LIMITATION OF LIABILITY</strong>
      </p>
      <p>
        You assume the sole risk of transmitting your information as it relates
        to the use of this site, and for any data corruptions, intentional
        interceptions, intrusions or unauthorized access to information, or of
        any delays, interruptions to or failures preventing the use this site.
        In no event shall we be liable for any direct, indirect, special,
        consequential or monetary damages, including fees, and penalties in
        connection with your use of materials posted on this site or
        connectivity to or from this site to any other site.
      </p>
      <p>&nbsp;</p>
      <p>7. PROTECTING YOUR CHILD&rsquo;S PRIVACY</p>
      <p>
        Even though our site is not designed for use by anyone under the age of
        18, we realize that a child may attempt to access the site. We do not
        knowingly collect personally identifiable information from a child. If
        you are a parent or guardian and believe your child is using our site,
        please contact us. We may ask for proof of identification before we
        remove any information to prevent malicious removal of information. If
        we discover on our own that a child is accessing our site, we will
        delete the information as soon as we discover it, we will not use the
        information for any purpose, and we will not disclose the information to
        third parties. You acknowledge that we do not verify the age of our
        users nor do we have any liability to do so. If you are a child, please
        seek the permission of a parent or guardian before accessing the site.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>8. CHANGES TO OUR PRIVACY POLICY</strong>
      </p>
      <p>
        We reserve the right to change this privacy policy at any time. If we
        decide to change this Privacy Policy, we will post those changes on the
        site so our users are always aware of what information we collect, use,
        and disclose. In all cases, your continued use of our site after any
        change to this Privacy Policy will constitute your acceptance of such
        change.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          9. ACCEPTANCE<span class="Apple-converted-space">&nbsp;</span>
        </strong>
      </p>
      <p>
        By accessing our site, you have willingly accepted the terms of this
        Privacy Policy.<span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );

  // return (
  //   <PageSetup title="Privacy Policy">
  //     <section className="mb-4">
  //       <div className="d-flex justify-content-between mb-3">
  //         <Content />
  //       </div>
  //     </section>
  //   </PageSetup>
  // );
};
