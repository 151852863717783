import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import { logoutService } from "../../services/auth";
import "./sidebar.scss";

export const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div
        className="menu-icon"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span className="iconify" data-icon="fe:app-menu"></span>
      </div>
      <div
        className={
          isOpen ? "sidebar-overlay  overlay-isOpen" : "sidebar-overlay"
        }
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="closeMenu">
          <span
            className="iconify"
            data-icon="ant-design:close-outlined"
          ></span>
        </div>
      </div>
      <section className={isOpen ? "sidebar sidebar-isOpen" : "sidebar"}>
        <div className="sidebar-wrapper">
          <div className="text-center side-logo mb-5">
            <img src={logo} alt="" />
          </div>

          <p className="font-bold">Menu</p>
          <ul className="mb-4">
            <li>
              <NavLink
                className="side-link"
                to="/dashboard"
                exact
                activeClassName="active"
              >
                <span className="side-icon">
                  <span
                    className="iconify"
                    data-icon="majesticons:dashboard"
                  ></span>
                </span>
                <span>Overview</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="side-link"
                to="/users"
                activeClassName="active"
              >
                <span className="side-icon">
                  <span
                    className="iconify"
                    data-icon="ph:users-three-fill"
                  ></span>
                </span>
                <span>Users</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="side-link"
                to="/waiting-list"
                exact
                activeClassName="active"
              >
                <span className="side-icon">
                  <span
                    className="iconify"
                    data-icon="clarity:users-solid-badged"
                  ></span>
                </span>
                <span>Waiting List</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="side-link"
                to="/reports"
                activeClassName="active"
              >
                <span className="side-icon">
                  <span
                    className="iconify"
                    data-icon="ic:baseline-report-problem"
                  ></span>
                </span>
                <span>Reports</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="side-link"
                to="/keys"
                activeClassName="active"
              >
                <span className="side-icon">
                  <span className="iconify" data-icon="emojione-v1:old-key"></span>
                </span>
                <span>Golden Keys</span>
              </NavLink>
            </li>
          </ul>

          <p className="font-bold">Other</p>
          <ul className="mb-4">
            <li>
              <NavLink
                className="side-link"
                to="/team"
                exact
                activeClassName="active"
              >
                <span className="side-icon">
                  <span
                    className="iconify"
                    data-icon="fa-solid:users-cog"
                  ></span>
                </span>
                <span>Team</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="side-link"
                to="/settings"
                exact
                activeClassName="active"
              >
                <span className="side-icon">
                  <span
                    className="iconify"
                    data-icon="fluent:calendar-settings-20-filled"
                  ></span>
                </span>
                <span>Settings</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="side-link"
                to="/"
                exact
                activeClassName="active"
                onClick={logoutService}
              >
                <span className="side-icon">
                  <span className="iconify" data-icon="mdi:logout"></span>
                </span>
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};
