import React, { useState } from "react";
import { PageSetup } from "../components/PageSetup";
import key from "../assets/img/key.png";
import { toast } from "react-toastify";
import { handleGeneralErrors } from "../utils/handleGeneralErrors";
import { FetchKeysRequest, revokeKeyRequest } from "../services/users";
import { useMutation, useQuery } from "@apollo/client";
import { EmptyList } from "../components/EmptyList";
import { BoxedComponentLoading } from "../components/LoadingComponents/BoxedComponentLoading";
import Pagination from "react-js-pagination";
import { DateTime } from "luxon";

export const GoldenKeys = () => {
  const [keys, setKeys] = useState([]);
  const [keysKept, setKeysKept] = useState([]);
  const [pagingInfo, setPagingInfo] = useState("");
  const [loading, setLoading] = useState(true);
  const [revokeLoading, setRevokeLoading] = useState("");

  const getKeys = useQuery(FetchKeysRequest, {
    onCompleted() {
      setLoading(false);
      updateQuery(getKeys.data.allGoldenKey);
    },
    onError(err) {
      setLoading(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
    variables: {
      current_page: "1",
    },
    fetchPolicy: "network-only",
  });

  const updateQuery = (res) => {
    setPagingInfo({
      current_page: parseInt(res.current_page),
      total: res.total,
      totalPerPage: res.totalPerPage,
    });
    setKeys(res.goldenKey);
    setKeysKept(res.goldenKey);
  };

  const handlePageChange = async (val) => {
    const res = await getKeys.fetchMore({
      variables: {
        current_page: `${val}`,
      },
    });
    updateQuery(res.data.allGoldenKey);
  };

  const askRevoke = (each) => {
    setRevokeLoading(each._id);
    if (window.confirm("Are you sure you want to revoke this key ?")) {
      revokeKey({
        variables: {
          key_id: each._id,
        },
      }).then((result) => {
        if (result.data.revokeGoldenKey.message) {
          const newlyRevoked = keysKept.find((r) => r._id === each._id);
          const index = keysKept.findIndex((r) => r._id === each._id);
          const list = [...keysKept];
          list.splice(index, 1, {
            ...newlyRevoked,
            assign_to_id: null,
            status: "revoked",
          });
          setKeys(list);
          setKeysKept(list);
        }
      });
    }
  };
  const [revokeKey] = useMutation(revokeKeyRequest, {
    update(proxy, result) {
      toast.success("Key revoked!");
      setRevokeLoading("");
    },
    onError(err) {
      setRevokeLoading("");
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
  });
  return (
    <PageSetup title="Golden Keys">
      {loading ? (
        <BoxedComponentLoading />
      ) : (
        <div className="row">
          {keysKept.length > 0 ? (
            <>
              {keys.map((each) => (
                <div
                  className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3"
                  key={each._id}
                >
                  <div
                    className={
                      each.status === "revoked"
                        ? "border box-shadow key-revoked"
                        : each.assign_to_id != null
                        ? "border box-shadow key-used"
                        : "border box-shadow"
                    }
                  >
                    <div className="d-flex align-items-center p-2 border-bottom">
                      <div className="me-2">
                        <img src={key} alt="" className="profile-img-sm" />
                      </div>
                      <div>
                        <div className="font18 font-bold">
                          {each.owner?.firstName}
                        </div>
                        <div className="font-bold text-grey">
                          @{each.owner?.username}
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom p-2 font14">
                      Key:<span className="text-blue"> {each.q_key}</span>
                      <br />
                      Generated by {each.generated_by?.full_name}
                      <div className=" font-bold">
                        <span className="text-red">
                          <span
                            className="iconify"
                            data-icon="clarity:date-outline-alerted"
                          ></span>
                        </span>
                        <span className="ms-1 font12 text-grey">
                          {DateTime.fromJSDate(
                            new Date(each.created_at)
                          ).toFormat("dd/MM/yyyy")}
                        </span>
                      </div>
                    </div>
                    <div className="py-2 font20 text-center">
                      {each.assign_to_id === null &&
                      each.status !== "revoked" ? (
                        <>
                          {revokeLoading === each._id ? (
                            <div className="spinner-border spinner-border-sm text-red mx-2" />
                          ) : (
                            <div
                              className="text-center click text-red"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Revoke Key"
                              onClick={() => {
                                askRevoke(each);
                              }}
                            >
                              <div>
                                <span
                                  className="iconify"
                                  data-icon="fluent:channel-dismiss-48-filled"
                                ></span>
                              </div>
                            </div>
                          )}
                        </>
                      ) : each.status === "revoked" ? (
                        <div className="text-red text-center font14 font-bold">
                          Revoked
                        </div>
                      ) : (
                        <div className="text-blue text-center font14 font-bold">
                          Used
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-center">
                {pagingInfo.total && (
                  <Pagination
                    activePage={pagingInfo.current_page}
                    itemsCountPerPage={10}
                    totalItemsCount={pagingInfo.total}
                    pageRangeDisplayed={5}
                    itemClass="page-item"
                    linkClass="page-link"
                    onChange={(e) => handlePageChange(e)}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="text-center">
              <EmptyList title="generated keys" />
            </div>
          )}
        </div>
      )}
    </PageSetup>
  );
};
