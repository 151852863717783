import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { handleGeneralErrors } from "../utils/handleGeneralErrors";
import { useMutation } from "@apollo/client";
import { GenerateKeyRequest } from "../services/users";

export const GenerateKey = ({ selectedUsers, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const initialValues = {
    number: "",
  };

  const validationSchema = yup.object().shape({
    number: yup.string().label("Number").required(),
  });

  const handleCreate = async (formValues, formikHelpers) => {
    if (parseFloat(formValues.number) > 0 && selectedUsers.length > 0) {
      setLoading(true);
      generateKey({
        variables: {
          ...formValues,
          user: selectedUsers,
        },
      }).then((value) => {
        if (value.data?.newGoldenKeys) {
          onSuccess();
          document.querySelector("#closeModal").click();
          formikHelpers.resetForm();
          toast.success("Key generated!!");
        }
      });
    } else {
      toast.warn("You have no user selected!!");
    }
  };

  const [generateKey] = useMutation(GenerateKeyRequest, {
    update(proxy, result) {
      setLoading(false);
    },
    onError(err) {
      setLoading(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
  });
  return (
    <div
      className="modal fade"
      id="generateKeyModal"
      tabIndex="-1"
      aria-labelledby="generateKeyModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="generateKeyModalLabel">
              Generate Golden Key
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCreate}
          >
            {({ handleSubmit, isValid, setFieldValue }) => {
              return (
                <>
                  <Form onSubmit={handleSubmit}>
                    <div className="modal-body">
                      <Field
                        className="form-control mb-4"
                        name="number"
                        id="number"
                        min="1"
                        type="number"
                        placeholder="Enter number of keys to generate"
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        id="closeModal"
                        type="button"
                        className="btn btn-grey"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="btn btn-green"
                        disabled={loading || !isValid}
                      >
                        <span className="me-2">Generate</span>
                        {loading && (
                          <span className="spinner-border spinner-border-sm" />
                        )}
                      </button>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
