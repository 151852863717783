import { gql } from "@apollo/client";

export const LoginUser = gql`
  mutation loginStaff($email: String!, $password: String) {
    loginStaff(email: $email, password: $password)
  }
`;

//logout
export const logoutService = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  window.location.replace("/");
};
