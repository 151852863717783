import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { CreateAdminRequest } from "../services/Account";
import { handleGeneralErrors } from "../utils/handleGeneralErrors";
import { useMutation } from "@apollo/client";

export const CreateAdmin = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const initialValues = {
    email: "",
    user_name: "",
    password: "",
    full_name: "",
    role: "",
    phone_number: "",
  };

  //   const phoneRegex = "^[0-9]*$";
  const validationSchema = yup.object().shape({
    email: yup.string().label("email").required(),
    password: yup.string().label("password").required(),
    user_name: yup.string().label("user name").required(),
    full_name: yup.string().label("full name").required(),
    role: yup.string().label("role").required(),
    phone_number: yup.string().label("phone number").required(),
    //   .matches(phoneRegex, "Enter a valid phone number"),
  });

  const handleCreate = async (formValues, formikHelpers) => {
    setLoading(true);
    createAdmin({
      variables: {
        ...formValues,
      },
    }).then((value) => {
      if (value.data?.createAdminAccount) {
        document.querySelector("#closeModal").click();
        formikHelpers.resetForm();
        onSuccess(value.data.createAdminAccount);
        toast.success("Admin Created Successfully!");
      }
    });
  };

  const [createAdmin] = useMutation(CreateAdminRequest, {
    update(proxy, result) {
      setLoading(false);
    },
    onError(err) {
      setLoading(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
  });
  return (
    <div
      className="modal fade"
      id="createAdminModal"
      tabIndex="-1"
      aria-labelledby="createAdminModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="createAdminModalLabel">
              Create Admin
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCreate}
          >
            {({ handleSubmit, isValid, setFieldValue }) => {
              return (
                <>
                  <Form onSubmit={handleSubmit}>
                    <div className="modal-body">
                      <Field
                        className="form-control mb-4"
                        name="full_name"
                        id="full_name"
                        placeholder="Enter Full Name"
                      />

                      <Field
                        className="form-control mb-4"
                        name="user_name"
                        id="user_name"
                        placeholder="Enter Username"
                      />

                      <Field
                        className="form-control mb-4"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="Enter your Email"
                      />

                      <Field
                        className="form-control mb-4"
                        name="phone_number"
                        id="phone_number"
                        placeholder="Enter Phone Number"
                      />

                      <Field
                        className="form-control mb-4"
                        name="role"
                        id="role"
                        as="select"
                        placeholder="Select role"
                      >
                        <option value="">Click to select role</option>
                        <option value="superAdmin">SuperAdmin</option>
                        <option value="moderator">Moderator</option>
                      </Field>
                      <Field
                        type="password"
                        className="form-control mb-4"
                        name="password"
                        id="password"
                        placeholder="**************"
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        id="closeModal"
                        type="button"
                        className="btn btn-grey"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="btn btn-green"
                        disabled={loading || !isValid}
                      >
                        <span className="me-2">Save changes</span>
                        {loading && (
                          <span className="spinner-border spinner-border-sm" />
                        )}
                      </button>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
