import React from "react";
import Skeleton from "react-skeleton-loader";

export const TableLoading = () => {
  return (
    <div>
      <table className="table table-hoverable text-nowrap">
        <tbody>
          {[...Array(6)].map((e, index) => (
            <tr key={index}>
              <td>
                <Skeleton color="#ced4da" width="100%" height="25px" />
              </td>
              <td>
                <Skeleton color="#ced4da" width="100%" height="25px" />
              </td>
              <td>
                <Skeleton color="#ced4da" width="100%" height="25px" />
              </td>
              <td>
                <Skeleton color="#ced4da" width="100%" height="25px" />
              </td>
              <td>
                <Skeleton color="#ced4da" width="100%" height="25px" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
