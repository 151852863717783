import React from "react";

export const TermsConditions = () => {
  return (
    <div className="container mt-5">
      <p>
        <h4>TERMS AND CONDITIONS</h4>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          1. ACCEPTING THESE TERMS
          <span class="Apple-converted-space">&nbsp;</span>
        </strong>
      </p>
      <p>
        This document, our rules, policies and the other documents referenced
        make up our Terms and Conditions (&ldquo;Terms&rdquo;). The Terms are a
        legally binding contract between you and us. This contract sets out your
        rights and responsibilities when you use our app. Please read them
        carefully.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          2. CHANGES<span class="Apple-converted-space">&nbsp;</span>
        </strong>
      </p>
      <p>
        FroClub may amend the Terms at any time by posting a revised version on
        our app. The revised version will be effective at the time we post it.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          3. ACCESS<span class="Apple-converted-space">&nbsp;</span>
        </strong>
      </p>
      <p>
        You are granted a non-exclusive, limited and revocable license to access
        the app and use its functionality on the condition that:
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        (a You only use the app for lawful purposes;
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        (b) You do not engage in any improper, indecent or offensive behavior
        while using the app; and
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        (c) You are not breaking any law in your relevant jurisdiction by
        accessing this app.<span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        <strong>4. OUR SERVICE</strong>
      </p>
      <ul>
        <li>
          <strong>Eligibility</strong>
        </li>
      </ul>
      <p>
        You may use FroClub only if you can form a legally binding contract with
        us, and only in compliance with these Terms and all applicable local,
        national, and international laws, rules and regulations. To use FroClub,
        you must be at least 18 years old, and in some circumstances even older
        (please check your local law for the age of digital consent). You
        represent and warrant that you meet the applicable age requirements and
        are competent to agree to these Terms, or, if you are unable to form a
        binding contract under applicable law, you represent and warrant that
        you have your parent&rsquo;s or legal guardian&rsquo;s permission to use
        FroClub, and that your parent or legal guardian is agreeing to these
        Terms concurrently. If you are a parent or legal guardian of a FroClub
        user who is unable to form a binding contract under applicable law, you
        are agreeing to these Terms and you are responsible for such FroClub
        user&rsquo;s activity on FroClub.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>Access, Restrictions, and Use</strong>
        </li>
      </ul>
      <p>
        Subject to your compliance with these Terms, you may access and use
        FroClub. Except as we otherwise agree in writing or to the extent a
        restriction is prohibited by law, you agree not to do, and not to
        assist, permit or enable any third party or Application (defined below)
        to do, any of the following:
      </p>
      <ul>
        <li>
          disassemble, reverse engineer, decode, or decompile any part of
          FroClub;
        </li>
        <li>
          use any robot, spider, scraper, data mining tool, data gathering or
          extraction tool, or any other automated means, to access, collect,
          copy or record FroClub;
        </li>
        <li>
          copy, rent, lease, sell, loan, transfer, assign, sublicense, resell,
          distribute, modify, alter, or create derivative works of any part of
          FroClub or any of our Intellectual Property (defined below);
        </li>
        <li>
          use FroClub in a manner that impacts: (i) the stability of our
          servers; (ii) the operation or performance of FroClub or any other
          user&rsquo;s use of FroClub; or (iii) the behavior of other
          applications using FroClub;
        </li>
        <li>
          use FroClub in any manner or for any purpose (including, without
          limitation, by providing FroClub with access to any Content) that: (i)
          violates or promotes the violation of any applicable law, regulation,
          legal requirement, contractual obligation, or right of any person
          including, but not limited to, intellectual property rights, rights of
          privacy, or rights of personality; (ii) is fraudulent, false,
          deceptive, or defamatory; (iii) promotes hatred, violence, or harm
          against any individual or group; (iv) otherwise may be harmful or
          objectionable (in our sole discretion) to us, our providers, our
          suppliers, or our users;
        </li>
        <li>
          use or display FroClub in competition with us, to develop competing
          products or services, for benchmarking or competitive analysis of
          FroClub, or otherwise to our detriment or disadvantage;
        </li>
        <li>
          attempt to interfere with, compromise the system integrity or security
          of, or decipher any transmissions to or from, the servers running
          FroClub;
        </li>
        <li>
          transmit viruses, worms, or other software agents through FroClub;
        </li>
        <li>
          impersonate another person or misrepresent your affiliation with a
          person or entity, hide or attempt to hide your identity, or otherwise
          use FroClub for any invasive or fraudulent purpose;
        </li>
        <li>
          share passwords or authentication credentials for FroClub, or
          otherwise circumvent the measures we may use to prevent or restrict
          access to FroClub or enforce limitations on use of FroClub; or
        </li>
        <li>
          identify or refer to us or FroClub in a manner that could reasonably
          imply an endorsement, relationship, or affiliation with or sponsorship
          between you or a third party and us, other than your permitted use of
          FroClub under these Terms, without our express written consent.
        </li>
      </ul>
      <ul>
        <li>
          <strong>FroClub Accounts</strong>
        </li>
      </ul>
      <p>
        In order to sign up on FroClub, you require a golden key. In the event
        you do not have a golden key, you are required to sign up to the waiting
        list.<span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        Once your account has been created, you will be able to sign in to the
        FroClub app. You can log in via email.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        You may not use another user&rsquo;s FroClub Account without such
        user&rsquo;s permission. You are solely responsible for the activity
        that occurs on your FroClub Account, and you must keep your FroClub
        Account password(s) strong and secure. You should notify us immediately
        of any breach of security or unauthorized use of your FroClub Account.
        Any individual with administrator-level access to your FroClub Account
        can modify your FroClub Account settings, access and billing
        information. We will not be liable for any losses caused by any
        unauthorized use of your FroClub Account, or for any changes to your
        FroClub Account, including your ability to access your FroClub Account
        or Your Content, made by any individual with administrator-level access
        to your FroClub Account.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>Your Content</strong>
        </li>
      </ul>
      <p>
        As between us and you, you (or your licensors) will own any and all
        information, data, and other content, in any form or medium, that is
        collected, downloaded, or otherwise received, directly or indirectly,
        from you (or on your behalf) by or through FroClub ("
        <strong>Your Content</strong>").
      </p>
      <p>&nbsp;</p>
      <p>
        You grant, and you represent and warrant that you have all rights
        necessary to grant, us an irrevocable, transferable, sub licensable
        (through multiple tiers), fully paid, royalty-free, and worldwide right
        and license to use, copy, store, modify, and display Your Content: (i)
        to provide, maintain, and improve FroClub; (ii) to perform such other
        actions as authorized by you in connection with your use of FroClub; and
        (iii) for any other purpose consistent with the Privacy Policy.
      </p>
      <p>
        If you are an individual using FroClub on behalf of an Organization and
        are collaborating with other employees or other individuals who have
        access to Your Content under your FroClub Account, or if you share Your
        Content with other individuals within or outside of such Organization,
        the content on your FroClub Account that you make available to such
        other individuals will be visible, accessible, and, depending on their
        designated level of access, editable by such individuals.
      </p>
      <p>
        You can remove Your Content from your FroClub Account by deleting it.
        However, in certain instances, some of Your Content may not be
        completely removed. We are not responsible or liable for the removal or
        deletion of any of Your Content, or any failure to remove or delete such
        content.
      </p>
      <p>
        In connection with Your Content, you represent and warrant that: (i) you
        have all necessary rights, licenses and consents to provide, receive,
        access and/or use Your Content and any other content you provide,
        receive, access and/or use through or in connection with FroClub; and
        (ii) Your Content and our use thereof as contemplated by these Terms and
        the Service will not violate any law or infringe any rights of any third
        party, including but not limited to any intellectual property rights and
        privacy rights.
      </p>
      <p>
        We take no responsibility and assume no liability for Your Content. You
        shall be solely responsible for Your Content and the consequences of
        posting it, publishing it, sharing it, or otherwise making it available
        on FroClub. You shall be solely responsible and indemnify us for Your
        Content.
      </p>
      <ul>
        <li>
          <strong>Service Changes, Suspension, and Termination</strong>
        </li>
      </ul>
      <p>
        You may cancel your FroClub Account at any time, though we will be sorry
        to see you go. We may change FroClub, stop providing FroClub or features
        of FroClub to you or to our users generally, or create usage limits for
        FroClub. We may permanently or temporarily terminate or suspend your
        access to FroClub without notice and liability, without cause or for any
        reason, including if in our sole determination you violate any provision
        of these Terms. Upon termination, you continue to be bound by these
        Terms.
      </p>
      <p>&nbsp;</p>
      <p>5. OUR INTELLECTUAL PROPERTY</p>
      <p>
        You acknowledge and agree that FroClub and all materials and content
        displayed or made available on FroClub, and all software, algorithms,
        code, technology and intellectual property underlying and included in or
        with FroClub, and all intellectual property rights therein and thereto
        throughout the world (collectively and individually, our "Intellectual
        Property"), are our (or our licensors&rsquo; as applicable) sole and
        exclusive property. Except as explicitly provided herein, nothing in
        these Terms will be deemed to create a license in or under any
        intellectual property rights, and you agree not to access, sell,
        license, rent, modify, distribute, copy, reproduce, transmit, publicly
        display, publicly perform, publish, adapt, edit or create derivative
        works from any of our Intellectual Property.
      </p>
      <p>&nbsp;</p>
      <p>
        You may choose to, or we may invite you to submit, comments, feedback or
        ideas about FroClub, including without limitation about how to improve
        FroClub or our products ("Feedback"). By submitting any Feedback, you
        agree that FroClub will own such Feedback, and that we are free to use
        the Feedback without any additional compensation to you, and/or to
        disclose the Feedback on a non-confidential basis or otherwise to
        anyone. You further acknowledge that, by acceptance of your submission,
        we do not waive any rights to use similar or related ideas previously
        known to us, or developed by our employees, or obtained from sources
        other than you.
      </p>
      <p>6. SUBSCRIPTION PLANS</p>
      <p>
        We may offer plans that you can sign up for that allow you to use
        certain aspects of FroClub, either for free or for a fee (a
        "Subscription Plan"). We may change Subscription Plans by offering new
        services for additional fees and charges and adding or amending fees and
        charges for existing Subscription Plans in our sole discretion. Any
        change to a Subscription Plan&rsquo;s pricing or payment terms will
        become effective in the billing cycle following notice of such change to
        you as provided in these Terms. Subscription Plans may set allotments
        for use of designated Service aspects.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>7. MOBILE APPLICATIONS</p>
      <p>
        We may make available software to access our Service via a compatible
        mobile device ("Mobile Applications"). You may incur mobile data charges
        from your wireless provider in connection with the Mobile Applications,
        and you agree that you are solely responsible for any such charges. We
        grant you a non-exclusive, non-transferable, revocable license to use a
        compiled code copy of the Mobile Applications for your FroClub Account
        on one or more mobile devices owned or leased solely by you, solely in
        accordance with these Terms. You acknowledge that we may from time to
        time issue upgraded versions of the Mobile Applications, and may
        automatically electronically upgrade the version of the Mobile
        Applications that you are using on your mobile device. You consent to
        such automatic upgrading on your mobile device, and agree that the terms
        and conditions of these Terms will apply to all such upgrades. Any
        third-party code that may be incorporated in the Mobile Applications is
        covered by the applicable open source or third-party license EULA, if
        any, authorizing use of such code. The foregoing license grant is not a
        sale of the Mobile Applications or any copy thereof. We or our
        third-party partners or suppliers retain all right, title, and interest
        in the Mobile Applications (and any copy thereof). Any attempt by you to
        transfer any of the rights, duties or obligations hereunder, except as
        expressly provided for in these Terms, is void.
      </p>
      <p>&nbsp;</p>
      <p>8. APP STORE TERMS</p>
      <p>
        If you acquire any Mobile Applications from any third-party app store:
        (i) you acknowledge that these Terms are between you and us only, and
        not with such third party; (ii) your use of such Mobile Applications
        must comply with such third party&rsquo;s then-current app store terms
        and conditions; (iii) such third party is only a provider of the app
        store where you obtained such Mobile Applications; (iv) we, and not such
        third party, are solely responsible for our Mobile Applications; (v)
        such third-party has no obligation or liability to you with respect to
        such Mobile Applications or these Terms; and (vi) you acknowledge and
        agree that such third-party is a third-party beneficiary to these Terms
        as it relates to such Mobile Applications.
      </p>
      <p>&nbsp;</p>
      <p>9. SECURITY</p>
      <p>
        We have implemented commercially reasonable technical and organizational
        measures designed to secure Your Content from accidental loss and from
        unauthorized access, use, alteration or disclosure. However, we cannot
        guarantee that unauthorized third parties will never be able to defeat
        those measures or use Your Content for improper purposes. You understand
        that internet technologies have the inherent potential for disclosure.
        You acknowledge that you provide Your Content at your own risk.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        10. THIRD-PARTY SERVICES I.E ADVERTISEMENTS
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        OUR SERVICE MAY CONTAIN LINKS TO THIRD-PARTY MATERIALS THAT ARE NOT
        OWNED OR CONTROLLED BY US, WE MAY REFER YOU TO CERTAIN THIRD PARTIES WHO
        PROVIDE INDEPENDENT SERVICES RELATING TO OR SUPPORTING YOUR USE OF OUR
        SERVICE, AND CERTAIN FUNCTIONALITY OF OUR SERVICE MAY REQUIRE YOUR USE
        OF, OR MAY BE COMPATIBLE WITH, THIRD-PARTY SERVICES, APPS, INFORMATION,
        MATERIALS, PRODUCTS, APPLICATIONS, OR SERVICES (EACH, A "THIRD-PARTY
        SERVICE"). IF YOU USE A THIRD-PARTY SERVICE, YOU ARE SUBJECT TO AND
        AGREE TO THE THIRD PARTY&rsquo;S TERMS OF SERVICE (OR OTHER APPLICABLE
        TERMS AND CONDITIONS) AND PRIVACY POLICY MADE AVAILABLE BY OR VIA THE
        THIRD-PARTY SERVICE. WE DO NOT ENDORSE OR ASSUME ANY RESPONSIBILITY FOR
        ANY SUCH THIRD-PARTY SERVICE. IF YOU ACCESS A THIRD-PARTY SERVICE FROM
        OR WITH FROCLUB OR SHARE YOUR CONTENT ON OR THROUGH ANY THIRD-PARTY
        SERVICE, YOU DO SO AT YOUR OWN RISK, AND YOU UNDERSTAND THAT THESE TERMS
        AND OUR PRIVACY POLICY DO NOT APPLY TO YOUR USE OF SUCH SERVICES. YOU
        EXPRESSLY RELIEVE US FROM ANY AND ALL LIABILITY ARISING FROM YOUR USE OF
        ANY THIRD-PARTY SERVICE.
      </p>
      <p>&nbsp;</p>
      <p>11. INDEMNITY</p>
      <p>
        You agree to defend, indemnify and hold us and our affiliates, agents,
        suppliers or licensors (and our and their employees, contractors,
        agents, officers and directors) harmless from and against any and all
        claims, damages, obligations, losses, liabilities, costs or debt, and
        expenses (including but not limited to attorney&rsquo;s fees) arising
        from: (i) your access to or use of FroClub; (ii) your violation of any
        aspect of these Terms, including without limitation your breach of any
        of your representations and warranties; (iii) your violation of any
        third-party right, including without limitation any right of privacy or
        intellectual property rights; (iv) your violation of any applicable law,
        rule or regulation; (v) Your Content, including without limitation any
        misleading, false, or inaccurate information in Your Content; (vi) your
        willful misconduct; or (vii) any third party&rsquo;s access to or use of
        FroClub with your username(s), password(s) or other security code(s).
      </p>
      <p>&nbsp;</p>
      <p>12. NO WARRANTY</p>
      <p>
        OUR SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. USE OF
        OUR SERVICE IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, OUR SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY KIND,
        WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
        NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
        OBTAINED BY YOU FROM US OR THROUGH OUR SERVICE WILL CREATE ANY WARRANTY
        NOT EXPRESSLY STATED HEREIN. WITHOUT LIMITING THE FOREGOING, WE, OUR
        SUBSIDIARIES, OUR AFFILIATES, AND OUR LICENSORS DO NOT WARRANT THAT ANY
        CONTENT ON OUR SERVICE IS ACCURATE, RELIABLE OR CORRECT; THAT OUR
        SERVICE WILL MEET YOUR REQUIREMENTS; THAT OUR SERVICE WILL BE AVAILABLE
        AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY
        DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT OUR SERVICE IS FREE OF
        VIRUSES OR OTHER HARMFUL COMPONENTS.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>13. LIMITATION OF LIABILITY</p>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE
        OR OUR AFFILIATES, AGENTS, SUPPLIERS OR LICENSORS (OR OUR OR THEIR
        EMPLOYEES, CONTRACTORS, AGENTS, OFFICERS OR DIRECTORS) BE LIABLE FOR ANY
        INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
        DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS,
        GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR
        RELATING TO THE USE OF, OR INABILITY TO USE, OUR SERVICE. UNDER NO
        CIRCUMSTANCES WILL WE BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY
        RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF
        OUR SERVICE OR YOUR ACCOUNT OR THE INFORMATION CONTAINED THEREIN.
      </p>
      <p>&nbsp;</p>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE ASSUME NO
        LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
        INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
        NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF OUR SERVICE;
        (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
        AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR
        CESSATION OF TRANSMISSION TO OR FROM OUR SERVICE; (V) ANY BUGS, VIRUSES,
        TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR
        SERVICE BY ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT
        OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT
        POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH OUR
        SERVICE; AND/OR (VII) YOUR DATA OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL
        CONDUCT OF ANY THIRD PARTY.
      </p>
      <p>
        IN NO EVENT WILL WE OR OUR AFFILIATES, AGENTS, SUPPLIERS OR LICENSORS
        (OR OUR OR THEIR EMPLOYEES, CONTRACTORS, AGENTS, OFFICERS OR DIRECTORS)
        BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS,
        DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO
        US HEREUNDER OR $100.00, WHICHEVER IS GREATER.
      </p>
      <p>
        THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED
        LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR
        ANY OTHER BASIS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGE.
      </p>
      <p>14. GENERAL</p>
      <p>14.1 Assignment</p>
      <p>
        These Terms, and any rights and licenses granted hereunder, may not be
        transferred or assigned by you without our prior express written
        consent, but may be assigned by us without restriction. Any attempted
        transfer or assignment in violation hereof will be null and void.
      </p>
      <p>&nbsp;</p>
      <p>14.2 Entire Agreement/Severability</p>
      <p>
        These Terms, together with any amendments and any additional agreements
        you may enter into with us in connection with FroClub, will constitute
        the entire agreement between you and us concerning FroClub. None of our
        employees or representatives are authorized to make any modification or
        addition to these Terms. Any statements or comments made between you and
        any of our employees or representatives are expressly excluded from
        these Terms and will not apply to you or us or your use of FroClub.
      </p>
      <p>&nbsp;</p>
      <p>14.3 No Waiver</p>
      <p>
        No waiver of any term of these Terms will be deemed a further or
        continuing waiver of such term or any other term, and our failure to
        assert any right or provision under these Terms will not constitute a
        waiver of such right or provision.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );
};
