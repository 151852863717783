import React from "react";
import Skeleton from "react-skeleton-loader";

export const BoxedComponentLoading = () => {
  return (
    <div className="row mt-5">
      {[...Array(6)].map((e, index) => (
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3" key={index}>
          <div style={{ width: "30%" }}>
            <Skeleton color="#ced4da" width="100%" height="25px" />
          </div>
          <div style={{ width: "60%", marginLeft: "auto" }}>
            <Skeleton color="#ced4da" width="100%" height="25px" />
          </div>
          <div>
            <Skeleton color="#ced4da" width="100%" height="25px" />
          </div>
          <div>
            <Skeleton color="#ced4da" width="100%" height="25px" />
          </div>
          <div>
            <Skeleton color="#ced4da" width="100%" height="25px" />
          </div>
          <div>
            <Skeleton color="#ced4da" width="100%" height="25px" />
          </div>
        </div>
      ))}
    </div>
  );
};

export const SingleBoxedComponentLoading = () => {
  return (
    <div>
      <div style={{ width: "30%" }}>
        <Skeleton color="#ced4da" width="100%" height="25px" />
      </div>
      <div style={{ width: "60%", marginLeft: "auto" }}>
        <Skeleton color="#ced4da" width="100%" height="25px" />
      </div>
      <div>
        <Skeleton color="#ced4da" width="100%" height="25px" />
      </div>
      <div>
        <Skeleton color="#ced4da" width="100%" height="25px" />
      </div>
      <div>
        <Skeleton color="#ced4da" width="100%" height="25px" />
      </div>
      <div>
        <Skeleton color="#ced4da" width="100%" height="25px" />
      </div>
    </div>
  );
};
