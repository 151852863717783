import React, { useState } from "react";
import { PageSetup } from "../../components/PageSetup";
import profile from "../../assets/img/user.png";
import { toast } from "react-toastify";
import { handleGeneralErrors } from "../../utils/handleGeneralErrors";
import { FetchReportsDetailsRequest } from "../../services/users";
import { useQuery } from "@apollo/client";
import { BoxedComponentLoading } from "../../components/LoadingComponents/BoxedComponentLoading";
import { DateTime } from "luxon";
import { useHistory, useParams } from "react-router";
import { WideComponentLoading } from "../../components/LoadingComponents/WideComponentLoading";

export const ReportDetailsPage = () => {
  const [reportsDetail, setReportsDetail] = useState();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { reportId } = useParams();

  const getReportsDetails = useQuery(FetchReportsDetailsRequest, {
    onCompleted() {
      setLoading(false);
      setReportsDetail(getReportsDetails.data.reportDetails);
    },
    onError(err) {
      setLoading(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
    variables: {
      report_id: reportId,
    },
  });
  const goback = () => {
    history.goBack();
  };
  return (
    <PageSetup title="Reports">
      {loading ? (
        <>
          {[...Array(3)].map((each, index) => (
            <WideComponentLoading key={index} />
          ))}
        </>
      ) : (
        <section>
          <div className="d-flex justify-content-between">
            {reportsDetail && (
              <p className="font-bold font24 text-black">
                {reportsDetail.reported_user?.firstName}'s report details
              </p>
            )}
            <p className="font-bold font18 click text-black" onClick={goback}>
              <span className="me-1">
                <span
                  className="iconify"
                  data-icon="eva:arrow-back-outline"
                ></span>
              </span>
              Back
            </p>
          </div>
          {reportsDetail ? (
            <>
              {reportsDetail.post.map((report, index) => (
                <div
                  className="br-5 mb-2 p-4 box-shadow border-red"
                  key={index}
                >
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="mb-2 d-flex align-items-center">
                      <span className="font16 font-medium text-black me-4">
                        Post {index + 1}
                      </span>
                      <span className={`badge badge-green`}>open</span>
                    </div>
                    <div className="mb-2 d-flex align-items-center">
                      <span className="font14 font-medium text-black me-2">
                        {DateTime.fromJSDate(
                          new Date(report.createdAt)
                        ).toLocaleString(DateTime.DATETIME_SHORT)}
                      </span>
                    </div>
                  </div>
                  <div>
                    <p className="font20 font-bold text-black">
                      {report.content?.title}
                    </p>
                    <p className="font16">{report.caption}</p>
                    <hr className="" />
                    <div className="d-flex justify-content-between flex-wrap">
                      <div>
                        <div className="font-medium text-black font14">
                          Posted By:
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="me-2">
                            <img
                              src={
                                reportsDetail.reported_user.displayPicture
                                  ? reportsDetail.reported_user.displayPicture
                                  : profile
                              }
                              alt=""
                              className="profile-img-sm"
                            />
                          </div>
                          <div className="font-medium text-black font14">
                            {reportsDetail.reported_user.firstName}{" "}
                            {reportsDetail.reported_user.lastName}
                          </div>
                        </div>
                        <div className="font14 font-medium text-black">
                          {DateTime.fromJSDate(
                            new Date(report.createdAt)
                          ).toLocaleString(DateTime.DATETIME_SHORT)}
                        </div>
                      </div>
                      <div>
                        <div className="font-medium text-black font14">
                          Reported By:
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="me-2">
                            <img
                              src={
                                reportsDetail.reported_by.displayPicture
                                  ? reportsDetail.reported_by.displayPicture
                                  : profile
                              }
                              alt=""
                              className="profile-img-sm"
                            />
                          </div>
                          <div className="font-medium text-black font14">
                            {reportsDetail.reported_by.firstName}{" "}
                            {reportsDetail.reported_by.lastName}
                          </div>
                        </div>
                        <div className="font14 font-medium text-black">
                          {DateTime.fromJSDate(
                            new Date(reportsDetail.createdAt)
                          ).toLocaleString(DateTime.DATETIME_SHORT)}
                        </div>

                        <div className="font14 font-medium text-black">
                          Note: {reportsDetail.note}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
        </section>
      )}
    </PageSetup>
  );
};
