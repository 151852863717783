import React from "react";
import "./auth.scss";
import logoWhite from "../assets/img/logo-white.svg";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { LoginUser } from "../services/auth";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

export const Login = () => {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/dashboard" } };
  const history = useHistory();
  const token = localStorage.getItem("token");
  if (token) {
    history.push(from);
  }
  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = yup.object().shape({
    email: yup.string().label("email").required(),
    password: yup.string().label("Password").required(),
  });

  const handleLogin = async (formValues) => {
    loginUser({
      variables: {
        ...formValues,
      },
    });
  };

  const [loginUser, { loading }] = useMutation(LoginUser, {
    update(proxy, result) {
      if (result.data) {
        if (result.data.loginStaff.statusCode === 200) {
          localStorage.setItem(
            "user",
            JSON.stringify(result.data.loginStaff.data.user)
          );
          localStorage.setItem("token", result.data.loginStaff.data.token);
          history.push(from);
        } else {
          toast.error(result.data.loginStaff.message);
        }
      }
    },
    onError(err) {
      toast.error("Sorry an error occurred!");
      // toast.error('err.graphQLErrors[0]?.message');
    },
  });

  return (
    <section className="login-wrapper">
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 mb-2 d-flex">
            <h2 className="align-self-center">Fro Community</h2>
          </div>
          <div className="col-lg-5 col-md-6 col-12">
            <div className="cad">
              <div className="cad-header p-4">
                <div>
                  <img src={logoWhite} alt="" className="logo mb-1" />
                </div>
                <h3 className="font-bold">A Fro-Positive Community</h3>
                <p className="pr-4">
                  A social networking platform solely for celebrating the
                  diverse black community.
                </p>
              </div>
              <div className="cad-body p-4">
                <div className="text-center font-bold font20 title text-black mb-4">
                  USER LOGIN
                </div>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleLogin}
                >
                  {({ handleSubmit, isValid, setFieldValue }) => {
                    return (
                      <>
                        <Form onSubmit={handleSubmit}>
                          <Field
                            className="form-control mb-4"
                            name="email"
                            id="email"
                            type="email"
                            placeholder="Enter your Email"
                          />
                          <Field
                            type="password"
                            className="form-control mb-4"
                            name="password"
                            id="password"
                            placeholder="**************"
                          />
                          <div className="text-center mt-5">
                            <button
                              type="submit"
                              className="btn btn-grey"
                              disabled={loading}
                            >
                              <span className="me-2">Login</span>
                              {loading && (
                                <span className="spinner-border spinner-border-sm" />
                              )}
                            </button>
                          </div>
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
