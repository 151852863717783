import React, { useState } from "react";
import { PageSetup } from "../components/PageSetup";
import profile from "../assets/img/user.png";
import { useMutation, useQuery } from "@apollo/client";
import { ApproveUserRequest, FetchWaitingListRequest } from "../services/users";
import { toast } from "react-toastify";
import { handleGeneralErrors } from "../utils/handleGeneralErrors";
import { TableLoading } from "../components/LoadingComponents/TableLoading";
import { DateTime } from "luxon";
import { EmptyList } from "../components/EmptyList";
import Pagination from "react-js-pagination";

export const WaitingList = () => {
  const [waiting, setWaiting] = useState([]);
  const [waitingKept, setWaitingKept] = useState([]);
  const [pagingInfo, setPagingInfo] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [approving, setApproving] = useState(false);

  const updateQuery = (res) => {
    setPagingInfo({
      current_page: parseInt(res.current_page),
      total: res.total,
      totalPerPage: res.totalPerPage,
    });
    setWaiting(res.user);
    setWaitingKept(res.user);
  };
  const getWaitingList = useQuery(FetchWaitingListRequest, {
    onCompleted() {
      setLoading(false);
      updateQuery(getWaitingList.data.waitingList);
    },
    onError(err) {
      setLoading(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
    variables: {
      current_page: "1",
      start_date: "",
      end_date: "",
    },
    fetchPolicy: "network-only",
  });

  const handlePageChange = async (val) => {
    const res = await getWaitingList.fetchMore({
      variables: {
        current_page: `${val}`,
      },
      fetchPolicy: "network-only",
    });
    updateQuery(res.data.waitingList);
  };

  const searchList = (event) => {
    const searchVal = event.target.value;
    if (searchVal) {
      let result = waitingKept.filter((val) => {
        return (
          val.email
            .toLowerCase()
            .trim()
            .includes(searchVal.toLowerCase().toString().trim()) ||
          val.firstName
            .toLowerCase()
            .trim()
            .includes(searchVal.toLowerCase().toString().trim()) ||
          val.lastName
            .toLowerCase()
            .trim()
            .includes(searchVal.toLowerCase().toString().trim()) ||
          val.username
            .toLowerCase()
            .trim()
            .includes(searchVal.toLowerCase().toString().trim())
        );
      });
      setWaiting(result);
    } else {
      setWaiting(waitingKept);
    }
  };

  const allUsersSelected = () => {
    const usersId = waiting.map((u) => u._id);
    return (
      usersId.every((_id) => selectedUsers.includes(_id)) ||
      selectedUsers.length === waiting.length
    );
  };

  const markAll = () => {
    const usersId = waiting.map((u) => u._id);
    !allUsersSelected() ? setSelectedUsers(usersId) : setSelectedUsers([]);
  };

  const isUserSelected = (userId) => {
    return selectedUsers.includes(userId);
  };

  const toggleUserSelection = (user, $event) => {
    !selectedUsers.includes(user._id)
      ? setSelectedUsers([...selectedUsers, user._id])
      : setSelectedUsers(selectedUsers.filter((uId) => uId !== user._id));
  };

  const [approve] = useMutation(ApproveUserRequest, {
    update(proxy, result) {
      toast.success("Approved!");
      setApproving(false);
      let filterList = waitingKept;
      for (let i = 0; i < filterList.length; i++) {
        filterList = filterList.filter((each) => each._id !== selectedUsers[i]);
      }
      setWaitingKept(filterList);
      setWaiting(filterList);
      setSelectedUsers([]);
    },
    onError(err) {
      setApproving(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
  });

  const approveUser = () => {
    setApproving(true);
    approve({
      variables: {
        user_id: selectedUsers,
      },
    }).then((result) => {
      setApproving(false);
    });
  };

  const handleFiltering = async (val) => {
    const currentDate = new Date();
    const thisYear = currentDate.getFullYear();
    const thisMonth = currentDate.getUTCMonth();
    // const thisWeek = currentDate.get()
    if (val === "all") {
      const res = await getWaitingList.fetchMore({
        variables: {
          current_page: "1",
        },
        fetchPolicy: "network-only",
      });
      updateQuery(res.data.waitingList);
    } else if (val === "year") {
      const start_date = new Date(thisYear, 0, 1);
      const end_date = new Date(thisYear, 11, 31);
      const res = await getWaitingList.fetchMore({
        variables: {
          current_page: "1",
          start_date,
          end_date,
        },
        fetchPolicy: "network-only",
      });
      updateQuery(res.data.waitingList);
    } else if (val === "month") {
      const start_date = new Date(thisYear, thisMonth, 1);
      const end_date = new Date(thisYear, thisMonth, 31);
      const res = await getWaitingList.fetchMore({
        variables: {
          current_page: "1",
          start_date,
          end_date,
        },
        fetchPolicy: "network-only",
      });
      updateQuery(res.data.waitingList);
    } else if (val === "week") {
      const first = currentDate.getDate() - currentDate.getDay();
      const last = first + 6;
      const start_date = new Date(currentDate.setDate(first));
      const end_date = new Date(currentDate.setDate(last));
      const res = await getWaitingList.fetchMore({
        variables: {
          current_page: "1",
          start_date,
          end_date,
        },
        fetchPolicy: "network-only",
      });
      updateQuery(res.data.waitingList);
    }
  };

  return (
    <PageSetup title="Waiting List...">
      <section className="mb-4">
        <div className="d-flex justify-content-between mb-3">
          <div className="input-container">
            <div className="input-icon">
              <span
                className="iconify"
                data-icon="bx:bx-search"
                data-inline="false"
              ></span>
            </div>
            <input
              type="text"
              placeholder="Search by Name"
              className="form-control"
              onChange={searchList}
            />
          </div>

          <div>
            <button
              className="btn btn-green"
              onClick={approveUser}
              disabled={approving || selectedUsers.length < 1}
            >
              Approve
              {approving && (
                <div className="spinner-border spinner-border-sm font20 ms-2"></div>
              )}
            </button>
          </div>
        </div>
        <div className="rounded box-shadow bg-white p-4">
          <div className="d-flex justify-content-between flex-wrap">
            {/* <h3 className="font-bold font30 text-dark mb-2">Waiting List...</h3> */}
            <ul className="nav nav-pills mb-2" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-allTime-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-allTime"
                  type="button"
                  role="tab"
                  aria-controls="pills-allTime"
                  aria-selected="true"
                  onClick={() => {
                    handleFiltering("all");
                  }}
                >
                  All Time
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-Yearly-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Yearly"
                  type="button"
                  role="tab"
                  aria-controls="pills-Yearly"
                  aria-selected="false"
                  onClick={() => {
                    handleFiltering("year");
                  }}
                >
                  This Year
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-Monthly-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Monthly"
                  type="button"
                  role="tab"
                  aria-controls="pills-Monthly"
                  aria-selected="false"
                  onClick={() => {
                    handleFiltering("month");
                  }}
                >
                  This Month
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-Weekly-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Weekly"
                  type="button"
                  role="tab"
                  aria-controls="pills-Weekly"
                  aria-selected="false"
                  onClick={() => {
                    handleFiltering("week");
                  }}
                >
                  This Week
                </button>
              </li>
            </ul>
          </div>

          <div className="tab-content" id="pills-tabContent">
            <div className="table-responsive">
              {loading ? (
                <TableLoading />
              ) : (
                <>
                  {waitingKept.length > 0 ? (
                    <table className="table caption-top text-nowrap">
                      <caption className="font-bold font20">
                        Active Users
                      </caption>
                      <thead>
                        <tr>
                          <th scope="col">
                            <input
                              type="checkbox"
                              checked={waiting.length === selectedUsers.length}
                              onChange={markAll}
                            />
                          </th>
                          <th scope="col" className="w-250px">
                            User Profile
                          </th>
                          <th scope="col">Email</th>
                          <th scope="col">Date Created</th>
                          <th scope="col">Age Range</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {waiting.map((each) => (
                          <tr key={each._id}>
                            <td className="w-50px">
                              <input
                                type="checkbox"
                                checked={isUserSelected(each._id)}
                                onChange={(e) => toggleUserSelection(each, e)}
                              />
                            </td>
                            <td className="w-250px">
                              <div className="d-flex align-items-center">
                                <div className="me-2">
                                  <img
                                    src={
                                      each.displayPicture
                                        ? each.displayPicture
                                        : profile
                                    }
                                    alt=""
                                    className="profile-img-sm"
                                  />
                                </div>
                                <div>
                                  <div className="font18 font-bold">
                                    {each.firstName}
                                    {each.lastName}
                                  </div>
                                  <div className="font-bold text-grey">
                                    @{each.username}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="w-200px">{each.email}</td>
                            <td>
                              {DateTime.fromJSDate(
                                new Date(each.createdAt)
                              ).toFormat("dd/MM/yyyy h:mm a")}
                            </td>
                            <td>{each.ageRange}yrs</td>
                            <td>{each.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center">
                      <EmptyList title="waiting user" />
                    </div>
                  )}
                </>
              )}
              <div className="d-flex justify-content-center">
                {pagingInfo.total && (
                  <Pagination
                    activePage={pagingInfo.current_page}
                    itemsCountPerPage={10}
                    totalItemsCount={pagingInfo.total}
                    pageRangeDisplayed={5}
                    itemClass="page-item"
                    linkClass="page-link"
                    onChange={(e) => handlePageChange(e)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageSetup>
  );
};
