import React, { useState } from "react";
import { PageSetup } from "../components/PageSetup";
import profile from "../assets/img/user.png";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import {
  UpdatePasswordRequest,
  UpdateProfilePictureRequest,
  UpdateProfileRequest,
} from "../services/Account";
import { toast } from "react-toastify";
import { handleGeneralErrors } from "../utils/handleGeneralErrors";

export const Settings = () => {
  const [passLoading, setPassLoading] = useState(false);
  const [accountLoading, setAccountLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [rawFile, setRawFile] = useState("");
  const [askSave, setAskSave] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [filePreview, setFilePreview] = useState(user.displayPicture);
  const initialValues = {
    full_name: user.full_name,
    email: user.email,
    phone_number: user.phone_number,
    role: user.role,
    user_name: user.user_name,
  };
  const validationSchema = yup.object().shape({
    full_name: yup.string().label("Name").required(),
    email: yup.string().label("Email").required(),
    phone_number: yup.string().label("Phone Number").required(),
    user_name: yup.string().label("Username").required(),
    role: yup.string().label("Role").required(),
  });
  const handleUpdate = async (formValues, formikHelpers) => {
    setAccountLoading(true);
    updateAccount({
      variables: {
        ...formValues,
        user_id: user.id,
      },
    }).then((value) => {
      if (value.data?.updateProfile) {
        localStorage.setItem("user", JSON.stringify(value.data.updateProfile));
        toast.success("Account Updated!");
      }
    });
  };

  const passwordInitialValues = {
    newPassword1: "",
    newPassword2: "",
  };
  const passwordValidationSchema = yup.object().shape({
    newPassword1: yup.string().label("New password").required(),
    newPassword2: yup
      .string()
      .label("Comfirm password")
      .required()
      .oneOf(
        [null, yup.ref("newPassword1")],
        "Both passwords needs to be same"
      ),
  });
  const handleUpdatePassword = async (formValues, formikHelpers) => {
    setPassLoading(true);
    updatePassword({
      variables: {
        password: formValues.newPassword,
        user_id: user.id,
      },
    }).then((value) => {
      if (value.data?.updatePassword) {
        formikHelpers.resetForm();
        toast.success(value.data.updatePassword.message);
      }
    });
  };

  const [updatePassword] = useMutation(UpdatePasswordRequest, {
    update(proxy, result) {
      setPassLoading(false);
    },
    onError(err) {
      setPassLoading(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
  });

  const [updateAccount] = useMutation(UpdateProfileRequest, {
    update(proxy, result) {
      setAccountLoading(false);
    },
    onError(err) {
      setAccountLoading(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
  });

  const changePicture = (event) => {
    setRawFile(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);
    fileReader.onloadend = () => {
      setFilePreview(fileReader.result);
    };
    setAskSave(true);
  };

  const [updatePicture] = useMutation(UpdateProfilePictureRequest, {
    update(proxy, result) {
      setFileLoading(false);
      localStorage.setItem(
        "user",
        JSON.stringify(result.data.updateProfilePicture.user)
      );
      toast.success("Picture Updated!");
    },
    onError(err) {
      setFileLoading(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
  });

  const handlePicture = async () => {
    setFileLoading(true);
    updatePicture({
      variables: {
        user_id: user.id,
        file: rawFile,
      },
    }).then((value) => {});
  };

  const removePicture = () => {
    setAskSave(false);
    setFilePreview(false);
    setRawFile(false);
  };
  return (
    <PageSetup title="Settings">
      <section className="settings-page row ">
        <section className="col-lg-8 col-12">
          <p className="font-bold font20">Account Setings</p>
          <section className="bg-white px-3 py-5 br-5 box-shadow border mb-5">
            <section className="d-flex flex-wrap align-items-center">
              <div className="me-3">
                <p className="mb-1 font-bold font14">Avatar</p>
                <img
                  src={filePreview ? filePreview : profile}
                  alt=""
                  className="profile-img"
                />
              </div>
              {fileLoading ? (
                <div className="spinner-border spinner-border-sm" />
              ) : (
                <>
                  {askSave ? (
                    <div className="d-flex mt-auto">
                      <div className="me-3">
                        <button
                          className="btn btn-green btn-sm"
                          onClick={handlePicture}
                        >
                          Save
                        </button>
                      </div>
                      <div>
                        <button
                          className="btn btn-outline-grey btn-sm"
                          onClick={removePicture}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="mt-auto">
                        <input
                          type="file"
                          id="picture"
                          name="picture"
                          onChange={changePicture}
                          accept="image/png,image/jpeg,image/jpg"
                          className="d-none"
                        />
                        <label
                          htmlFor="picture"
                          className="btn btn-outline-grey text-blue btn-sm"
                        >
                          Upload
                        </label>
                      </div>
                    </>
                  )}
                </>
              )}
            </section>
            <hr />
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleUpdate}
            >
              {({ handleSubmit, isValid }) => {
                return (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="font14 font-bold">
                            Display Name
                          </label>
                          <Field
                            className="form-control mb-4"
                            name="full_name"
                            id="full_name"
                            type="text"
                            placeholder="Enter your Name"
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="font14 font-bold">Username</label>
                          <Field
                            className="form-control mb-4"
                            name="user_name"
                            id="user_name"
                            type="text"
                            placeholder="Enter username"
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="font14 font-bold">Role</label>
                          <Field
                            className="form-control mb-4"
                            name="role"
                            id="role"
                            as="select"
                            // readOnly={user.role != "superAdmin"}
                          >
                            <option value="">Click to select role</option>
                            <option value="superAdmin">SuperAdmin</option>
                            <option value="moderator">Moderator</option>
                          </Field>
                        </div>
                        <div className="col-12">
                          <hr />
                        </div>
                        <div className="col-md-6">
                          <label className="font14 font-bold">
                            Email Address
                          </label>
                          <Field
                            className="form-control mb-4"
                            name="email"
                            id="email"
                            type="email"
                            placeholder="Enter your Email"
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="font14 font-bold">
                            Phone Number
                          </label>
                          <Field
                            className="form-control mb-4"
                            name="phone_number"
                            id="phone_number"
                            type="text"
                            placeholder="Enter your Tel. Number"
                          />
                        </div>
                      </div>
                      <div className="text-end mt-5">
                        <button
                          type="submit"
                          className="btn btn-green"
                          disabled={accountLoading || !isValid}
                        >
                          Update
                          {accountLoading && (
                            <span className="spinner-border spinner-border-sm" />
                          )}
                        </button>
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </section>
        </section>
        <section className="col-lg-4 col-12">
          <p className="font-bold font20">Change Password</p>
          <div className="bg-white px-3 py-5 br-5 box-shadow border mb-5">
            <Formik
              initialValues={passwordInitialValues}
              validationSchema={passwordValidationSchema}
              onSubmit={handleUpdatePassword}
            >
              {({ handleSubmit, isValid }) => {
                return (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <div className="mb-4">
                        <label className="font14 font-bold">
                          Enter New Password
                        </label>
                        <Field
                          className="form-control"
                          name="newPassword1"
                          id="newPassword1"
                          type="password"
                          placeholder="Enter your new password"
                        />
                        <ErrorMessage
                          name="newPassword1"
                          component="div"
                          className="text-red font12"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="font14 font-bold">
                          Confirm Password
                        </label>
                        <Field
                          className="form-control"
                          name="newPassword2"
                          id="newPassword2"
                          type="password"
                          placeholder="Confirm your password"
                        />
                        <ErrorMessage
                          name="newPassword2"
                          component="div"
                          className="text-red font12"
                        />
                      </div>
                      <div className="text-end mt-5">
                        <button
                          type="submit"
                          className="btn btn-green"
                          disabled={passLoading || !isValid}
                        >
                          Change
                          {passLoading && (
                            <span className="spinner-border spinner-border-sm" />
                          )}
                        </button>
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </section>
      </section>
    </PageSetup>
  );
};
