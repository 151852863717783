import React from "react";

export const Cookies = () => {
  return (
    <div className="container mt-5">
      <p>
        <h4>COOKIE POLICY</h4>
      </p>
      <p>&nbsp;</p>
      <p>
        Please read the following carefully to understand our policies and
        practices regarding your personal information and how FroClub will treat
        it. By using or accessing our site, you agree to this privacy policy.
        This policy may change from time to time and your continued use of our
        site is deemed to be acceptance of such changes, so please check the
        policy periodically for updates.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          1. ABOUT COOKIES<span class="Apple-converted-space">&nbsp;</span>
        </strong>
      </p>
      <p>
        A cookie is a small text file containing a unique identification number
        that is placed on the hard disk of your computer by a web page server,
        enabling the web server to identify your web browser and passively track
        its activities on that site. A cookie does not reveal any personally
        identifiable information that you have not otherwise disclosed. Cookies
        do not contain any executable code and cannot be used for infecting your
        computer with a virus. Most web browsers automatically accept cookies
        but usually provide the option of configuring your browser to accept all
        cookies, reject all cookies, or notify you when a cookie is sent. You
        can change your settings in the preferences or options menu of your
        browser. If you choose to decline cookies, certain features of our site
        may not be available to you.
      </p>
      <p>&nbsp;</p>
      <p>2. HOW DOES FROCLUB USE COOKIES?</p>
      <p>
        In line with standard industry practice, we use cookies to track site
        usage. We use cookies that are strictly necessary to enable/allow you to
        move around our site. FroClub also uses cookies to improve your
        experience by remembering your details so that you do not need to keep
        re-entering them when navigating our site.
      </p>
      <p>&nbsp;</p>
      <p>
        One of the reasons this site uses cookies is to make sure that people
        are not counted double. This analytics cookie does not store any
        personal details like your name or email address. It&rsquo;s just there
        to help us find out what&rsquo;s popular among site visitors.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          3. COOKIES THAT FROCLUB USES
          <span class="Apple-converted-space">&nbsp;</span>
        </strong>
      </p>
      <p>
        3.1 FroClub uses a few services to help us understand how people use our
        site and the problems they might encounter as they use it.{" "}
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        3.1.1.&nbsp;Google Analytics:&nbsp;We use Google Analytics (which uses
        cookies) to help us understand what actions people take when they are
        using the site, and information about the device the user is using to
        access the site. All of the data we track in Google Analytics is
        anonymous and there is no personal identifying information contained
        therein. To opt out of our usage of Google Analytics, you can
        use&nbsp;this opt-out tool from Google. Google has more information
        about the cookies they use and how you can be aware of what's being
        tracked.<span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        <br />
        3.2 FroClub uses cookies for the following purposes:
      </p>
      <p>
        (a) Authentication &ndash; we use cookies to identify you when you visit
        our site and as you navigate our site;
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        (b) Status &ndash; we use cookies to help us to determine if you are
        logged into our site;<span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        (c) Personalization &ndash; we use cookies to store information about
        your preferences and to personalize the site for you;
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        (d) Security &ndash; we use cookies as an element of the security
        measures used to protect users.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          4. HOW TO DELETE COOKIES
          <span class="Apple-converted-space">&nbsp;</span>
        </strong>
      </p>
      <p>
        You can control your cookies, delete them and reject them in the
        settings of your browser.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          5. ACCEPTANCE<span class="Apple-converted-space">&nbsp;</span>
        </strong>
      </p>
      <p>
        Your access to and use of FroClub is conditioned on your acceptance of
        and compliance with these Cookie Policy. These Terms apply to all
        visitors, users and others who access or use the site.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        By using the site and agreeing to this policy, you consent to our use of
        cookies in accordance with the terms given herein.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
    </div>
  );
};
