import React, { useState } from "react";
import { PageSetup } from "../components/PageSetup";
import profile from "../assets/img/user.png";
import { EmptyList } from "../components/EmptyList";
import Pagination from "react-js-pagination";
import { BoxedComponentLoading } from "../components/LoadingComponents/BoxedComponentLoading";
import { useMutation, useQuery } from "@apollo/client";
import {
  ActivateStaffRequest,
  DeactivateStaffRequest,
  GetStaffs,
} from "../services/users";
import { handleGeneralErrors } from "../utils/handleGeneralErrors";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import { CreateAdmin } from "../components/createAdmin";
import { Icon } from "@iconify/react";

export const Team = () => {
  const [teamList, setTeamList] = useState([]);
  const [teamListKept, setTeamListKept] = useState([]);
  const [pagingInfo, setPagingInfo] = useState("");
  const [loading, setLoading] = useState(true);
  const [changing, setChanging] = useState("");

  const getTeamList = useQuery(GetStaffs, {
    onCompleted() {
      setLoading(false);
      updateQuery(getTeamList.data.staffs);
    },
    onError(err) {
      setLoading(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
    variables: {
      current_page: "1",
    },
  });

  const updateQuery = (res) => {
    setPagingInfo({
      current_page: parseInt(res.current_page),
      total: res.total,
      totalPerPage: res.totalPerPage,
    });
    setTeamList(res.staff);
    setTeamListKept(res.staff);
  };

  const handlePageChange = async (val) => {
    const res = await getTeamList.fetchMore({
      variables: {
        current_page: `${val}`,
        reference: "",
        field: "",
        start_date: "",
        end_date: "",
      },
    });
    updateQuery(res.data.staffs);
  };

  const searchUser = (event) => {
    const searchVal = event.target.value;
    if (searchVal) {
      let result = teamListKept.filter((val) => {
        return (
          val.email
            .toLowerCase()
            .trim()
            .includes(searchVal.toLowerCase().toString().trim()) ||
          val.user_name
            .toLowerCase()
            .trim()
            .includes(searchVal.toLowerCase().toString().trim()) ||
          val.full_name
            .toLowerCase()
            .trim()
            .includes(searchVal.toLowerCase().toString().trim())
        );
      });
      setTeamList(result);
    } else {
      setTeamList(teamListKept);
    }
  };

  const [deactivate] = useMutation(DeactivateStaffRequest, {
    update(proxy, result) {
      setChanging("");
      toast.success("User deactivated!");
    },
    onError(err) {
      setChanging("");
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
  });

  const deactivateUser = (user) => {
    if (window.confirm("Are you sure you want to deactivate this user?")) {
      setChanging(user._id);
      deactivate({
        variables: {
          user_id: user._id,
        },
      }).then((value) => {
        if (value.data.deactivateStaff.message) {
          const index = teamListKept.findIndex(
            (t) => t._id === user._id || t.id === user._id
          );
          const newUser = {
            ...user,
            status: "in-active",
          };
          const list = [...teamListKept];
          list.splice(index, 1, newUser);
          setTeamListKept(list);
          setTeamList(list);
        }
      });
    }
  };

  const [activate] = useMutation(ActivateStaffRequest, {
    update(proxy, result) {
      setChanging("");
      toast.success("User activated!");
    },
    onError(err) {
      setChanging("");
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
  });

  const activateUser = (user) => {
    if (window.confirm("Are you sure you want to activate this user?")) {
      setChanging(user._id);
      activate({
        variables: {
          user_id: user._id,
        },
      }).then((value) => {
        if (value.data.activateStaff.message) {
          const index = teamListKept.findIndex(
            (t) => t._id === user._id || t.id === user._id
          );
          const newUser = {
            ...user,
            status: "active",
          };
          const list = [...teamListKept];
          list.splice(index, 1, newUser);
          setTeamListKept(list);
          setTeamList(list);
        }
      });
    }
  };

  const successFunction = (team) => {
    setTeamList([
      {
        ...team,
      },
      ...teamList,
    ]);

    setTeamListKept([
      {
        ...team,
      },
      ...teamListKept,
    ]);
  };

  return (
    <PageSetup title="Team">
      {loading ? (
        <BoxedComponentLoading />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            {teamListKept.length > 0 && (
              <div className="input-container mb-5">
                <div className="input-icon">
                  <span
                    className="iconify"
                    data-icon="bx:bx-search"
                    data-inline="false"
                  ></span>
                </div>
                <input
                  type="text"
                  placeholder="Search by Name"
                  className="form-control"
                  onChange={searchUser}
                />
              </div>
            )}
            <div>
              <button
                className="btn btn-green"
                data-bs-toggle="modal"
                data-bs-target="#createAdminModal"
              >
                Create Admin
              </button>
            </div>
          </div>
          {teamListKept.length > 0 ? (
            <>
              <div className="row">
                {teamList.map((each) => (
                  <div
                    className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3"
                    key={each._id ? each._id : each.id}
                  >
                    <div className="border box-shadow report-cards">
                      <div className="d-flex align-items-center p-2 border-bottom">
                        <div className="me-2">
                          <img
                            src={
                              each.displayPicture
                                ? each.displayPicture
                                : profile
                            }
                            alt=""
                            className="profile-img-sm"
                          />
                        </div>
                        <div>
                          <div className="font18 font-bold">
                            {each.full_name}
                          </div>
                          <div className="font-bold text-grey">
                            @{each.user_name}
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom p-2 font14">
                        <span className="text-capitalize">{each.role}</span>
                        <div className=" font-bold">
                          <span className="text-green">
                            <span
                              className="iconify"
                              data-icon="healthicons:i-schedule-school-date-time"
                            ></span>
                          </span>
                          <span className="ms-1 font12 text-grey">
                            {DateTime.fromJSDate(
                              new Date(each.createdAt)
                            ).toFormat("dd/MM/yyyy")}
                          </span>
                        </div>
                      </div>
                      <div className="text-center">
                        {changing === each._id ? (
                          <div className="spinner-border spinner-border-sm text-red mx-2" />
                        ) : (
                          <div>
                            {each.status == "in-active" ? (
                              <div
                                className="font20 text-center click py-2 text-green"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Activate User"
                                onClick={() => activateUser(each)}
                              >
                                <Icon icon="codicon:vm-active" />
                              </div>
                            ) : (
                              <div
                                className="font20 text-center click py-2 text-red"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Deactivate User"
                                onClick={() => deactivateUser(each)}
                              >
                                <Icon icon="bx:bxs-virus-block" />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-center">
                {pagingInfo.total && (
                  <Pagination
                    activePage={pagingInfo.current_page}
                    itemsCountPerPage={10}
                    totalItemsCount={pagingInfo.total}
                    pageRangeDisplayed={5}
                    itemClass="page-item"
                    linkClass="page-link"
                    onChange={(e) => handlePageChange(e)}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="text-center">
              <EmptyList title="Team" />
            </div>
          )}
        </>
      )}
      <CreateAdmin onSuccess={successFunction} />
    </PageSetup>
  );
};
