import React from "react";
import logo from "../../assets/img/logo.svg";
import profile from "../../assets/img/user.png";
import { logoutService } from "../../services/auth";
import "./navbar.scss";

export const Navbar = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <nav className="navbar navbar-expand-lg navbar-light navbar-wrapper">
      <div className="container-fluid">
        <a className="navbar-brand font-bold font25" href="#!">
          <img src={logo} alt="" className="lg-d-none" />
          <span className="sm-d-none">{props.title}</span>
        </a>

        <div>
          <ul className="navbar-nav">
            <li className="nav-item">
              <div className="nav-link pe-0">
                <img
                  src={user.displayPicture ? user.displayPicture : profile}
                  alt=""
                  className="profile-img"
                />
              </div>
            </li>
            <li className="nav-item dropdown align-self-center">
              <div
                className="nav-link dropdown-toggle ps-1"
                id="navbarDarkDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {user.full_name}
              </div>
              <ul
                className="dropdown-menu dropdown-menu-dark"
                aria-labelledby="navbarDarkDropdownMenuLink"
              >
                <li>
                  <a className="dropdown-item" href="/settings">
                    Account Settings
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/" onClick={logoutService}>
                    Logout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
