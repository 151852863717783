import { toast } from "react-toastify";
import { logoutService } from "../services/auth";

export const handleGeneralErrors = (errors) => {
  errors.forEach((err) => {
    if (
      err.message === "Authorization header must be provided" ||
      err.message === "Invalid/Expired token"
    ) {
      toast.error("Access Denied!");
      logoutService();
    } else {
      toast.error(err.message);
    }
  });
};
