import React, { useState } from "react";
import { LineGraph } from "../components/LineGraph";
import { PageSetup } from "../components/PageSetup";
import { PieChart } from "../components/PieChart";
import profile from "../assets/img/user.png";
import { useMutation, useQuery } from "@apollo/client";
import { ApproveUserRequest, FetchDashboardRequest } from "../services/users";
import { toast } from "react-toastify";
import { handleGeneralErrors } from "../utils/handleGeneralErrors";
import { SingleBoxedComponentLoading } from "../components/LoadingComponents/BoxedComponentLoading";
import { WideComponentLoading } from "../components/LoadingComponents/WideComponentLoading";
import { TableLoading } from "../components/LoadingComponents/TableLoading";
import { DateTime } from "luxon";

const DashCard = ({ icon, percent, percentTag, tag, number, addOn, color }) => {
  return (
    <>
      <div className="dashboard-card">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className={color ? `dash-icon ${color}` : "dash-icon"}>
            <span className="iconify" data-icon={icon}></span>
          </div>
          <div className="font12">
            {parseFloat(percent) < 0 ? (
              <>
                <div className="text-red font-bold">
                  <span className="">
                    <span
                      className="iconify"
                      data-icon="bx:bx-down-arrow-alt"
                    ></span>
                  </span>
                  <span>-{parseInt(percent.substring(1))}</span>
                </div>
              </>
            ) : (
              <div className="text-green font-bold">
                <span className="">
                  <span
                    className="iconify"
                    data-icon="bx:bx-up-arrow-alt"
                  ></span>
                </span>
                <span>+{parseInt(percent.substring(1))}</span>
              </div>
            )}
            <div className="text-grey">{percentTag}</div>
          </div>
        </div>
        <div>
          <div className="font-bold font14">{tag}</div>
          <div className="font-bold text-dark font35 line-height-1">
            {number}
            <span className="font14 text-grey">{addOn}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export const Dashboard = () => {
  const [dashData, setDashData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [approving, setApproving] = useState(false);

  const getDashData = useQuery(FetchDashboardRequest, {
    onCompleted() {
      setLoading(false);
      setDashData(getDashData.data.dashboard);
    },
    onError(err) {
      setLoading(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
    fetchPolicy: "network-only",
  });
  const allUsersSelected = () => {
    const usersId = dashData.wait_list.map((u) => u._id);
    return (
      usersId.every((_id) => selectedUsers.includes(_id)) ||
      selectedUsers.length === dashData?.wait_list.length
    );
  };

  const markAll = () => {
    const usersId = dashData.wait_list.map((u) => u._id);
    !allUsersSelected() ? setSelectedUsers(usersId) : setSelectedUsers([]);
  };

  const isUserSelected = (userId) => {
    return selectedUsers.includes(userId);
  };

  const toggleUserSelection = (user, $event) => {
    !selectedUsers.includes(user._id)
      ? setSelectedUsers([...selectedUsers, user._id])
      : setSelectedUsers(selectedUsers.filter((uId) => uId !== user._id));
  };
  const [approve] = useMutation(ApproveUserRequest, {
    update(proxy, result) {
      toast.success("Approved!");
      setApproving(false);
      let filterList = dashData.wait_list;
      for (let i = 0; i < filterList.length; i++) {
        filterList = filterList.filter((each) => each._id !== selectedUsers[i]);
      }
      setDashData({
        ...dashData,
        wait_list: filterList,
      });
      setSelectedUsers([]);
    },
    onError(err) {
      setApproving(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
  });

  const approveUser = () => {
    setApproving(true);
    approve({
      variables: {
        user_id: selectedUsers,
      },
    }).then((result) => {
      setApproving(false);
    });
  };
  return (
    <>
      <PageSetup title="Dashboard">
        {loading ? (
          <>
            <div className="row">
              {[...Array(4)].map((e) => (
                <div className="col-lg-3 col-md-4 col-6 mb-2" key={e}>
                  <SingleBoxedComponentLoading />
                </div>
              ))}
            </div>
            <section className="my-4">
              <WideComponentLoading />
            </section>
            <section className="row my-4">
              {[...Array(3)].map((e) => (
                <div className="col-md-4 col-12 mb-2" key={e}>
                  <WideComponentLoading />
                </div>
              ))}
            </section>
            <section className="my-4">
              <TableLoading />
            </section>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-6 mb-2">
                <DashCard
                  icon="fa-solid:user-alt"
                  percent={`${dashData.users?.type}${dashData.users?.percentage}%`}
                  percentTag="This Month"
                  tag={"Active Users"}
                  number={dashData.users?.count}
                />
              </div>
              <div className="col-lg-3 col-md-4 col-6 mb-2">
                <DashCard
                  icon="entypo:time-slot"
                  percent={`${dashData.retention?.type}${dashData.retention?.percentage}%`}
                  percentTag="This Month"
                  tag={"Average Retention"}
                  number={dashData.retention?.count}
                  addOn={"hrs"}
                />
              </div>
              <div className="col-lg-3 col-md-4 col-6 mb-2">
                <DashCard
                  icon="noto:woman-mage-medium-dark-skin-tone"
                  percent={`${dashData.age?.type}${dashData.age?.percentage}%`}
                  percentTag="This Month"
                  tag={"Average Age"}
                  number={dashData.age?.count}
                  addOn={"yrs"}
                />
              </div>
              <div className="col-lg-3 col-md-4 col-6 mb-2">
                <DashCard
                  icon="ic:twotone-report-problem"
                  color="text-red"
                  percent={`${dashData.report?.type}${dashData.report?.percentage}%`}
                  percentTag="This Month"
                  tag={"Total Report"}
                  number={dashData.report?.count}
                />
              </div>
            </div>
            <section className="my-4">
              <div className="rounded box-shadow bg-white p-4">
                <div className="d-flex justify-content-between">
                  <h3 className="font-bold font30 text-dark">Users' Growth</h3>
                </div>
                <div>
                  <LineGraph chartData={dashData.chart?.growth} />
                </div>
              </div>
            </section>
            <section className="row my-4">
              <div className="col-md-4 col-12 mb-2">
                <div className="rounded box-shadow bg-white p-4 h-100">
                  <h3 className="font-bold font20 text-dark">
                    Men, Women & Other
                  </h3>
                  <PieChart chartData={dashData.chart?.gender} />
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div className="rounded box-shadow bg-white p-4 h-100">
                  <h3 className="font-bold font20 text-dark">Hair Types</h3>
                  <PieChart chartData={dashData.chart?.hair_types} />
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div className="rounded box-shadow bg-white p-4 h-100">
                  <h3 className="font-bold font20 text-dark">
                    Top Location of users
                  </h3>
                  <PieChart chartData={dashData.chart?.locations} />
                </div>
              </div>
            </section>
            <section className="my-4">
              {dashData.wait_list.length > 0 && (
                <div className="rounded box-shadow bg-white p-4">
                  <div className="d-flex justify-content-between flex-wrap">
                    <h3 className="font-bold font30 text-dark mb-2">
                      Waiting List...
                    </h3>
                    {selectedUsers.length > 0 && (
                      <div>
                        <button
                          className="btn btn-green"
                          onClick={approveUser}
                          disabled={approving || selectedUsers.length < 1}
                        >
                          Approve
                          {approving && (
                            <div className="spinner-border spinner-border-sm font20 ms-2"></div>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="table-responsive">
                    <table className="table caption-top text-nowrap">
                      <caption>Showing the new joiners on the list</caption>

                      <thead>
                        <tr>
                          <th scope="col">
                            <input
                              type="checkbox"
                              checked={
                                dashData?.wait_list.length ===
                                selectedUsers.length
                              }
                              onChange={markAll}
                            />
                          </th>
                          <th scope="col" className="w-250px">
                            User Profile
                          </th>
                          <th scope="col">Email</th>
                          <th scope="col">Date and Time</th>
                          <th scope="col">Age</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashData.wait_list.map((each) => (
                          <tr key={each._id}>
                            <td className="w-50px">
                              <input
                                type="checkbox"
                                checked={isUserSelected(each._id)}
                                onChange={(e) => toggleUserSelection(each, e)}
                              />
                            </td>
                            <td className="w-250px">
                              <div className="d-flex align-items-center">
                                <div className="me-2">
                                  <img
                                    src={
                                      each.displayPicture
                                        ? each.displayPicture
                                        : profile
                                    }
                                    alt=""
                                    className="profile-img-sm"
                                  />
                                </div>
                                <div>
                                  <div className="font18 font-bold">
                                    {each.firstName}
                                    {each.lastName}
                                  </div>
                                  <div className="font-bold text-grey">
                                    @{each.username}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="w-200px">{each.email}</td>
                            <td>
                              {DateTime.fromJSDate(
                                new Date(each.createdAt)
                              ).toFormat("dd/MM/yyyy h:mm a")}
                            </td>
                            <td>{each.ageRange}yrs</td>
                            <td>{each.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </section>
          </>
        )}
      </PageSetup>
    </>
  );
};
