import React from "react";
import "./styles.scss";

export const EmptyList = ({ title }) => {
  return (
    <div className="dashboard-empty-list">
      <span>Oops, no {title}</span>
    </div>
  );
};
