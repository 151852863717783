import React from "react";
import Skeleton from "react-skeleton-loader";

export const WideComponentLoading = () => {
  return (
    <div className="my-3">
      <Skeleton color="#ced4da" width="100%" height="150px" />
    </div>
  );
};
