import React, { useState } from "react";
import { PageSetup } from "../../components/PageSetup";
import profile from "../../assets/img/user.png";
import { toast } from "react-toastify";
import { handleGeneralErrors } from "../../utils/handleGeneralErrors";
import { CancelReportRequest, FetchReportsRequest } from "../../services/users";
import { useMutation, useQuery } from "@apollo/client";
import { EmptyList } from "../../components/EmptyList";
import { BoxedComponentLoading } from "../../components/LoadingComponents/BoxedComponentLoading";
import Pagination from "react-js-pagination";
import { DateTime } from "luxon";
import { useHistory } from "react-router";

export const ReportsPage = () => {
  const [reports, setReports] = useState([]);
  const [reportsKept, setReportsKept] = useState([]);
  const [pagingInfo, setPagingInfo] = useState("");
  const [loading, setLoading] = useState(true);
  const [cancelLoading, setCancelLoading] = useState("");
  const history = useHistory();

  const getReports = useQuery(FetchReportsRequest, {
    onCompleted() {
      setLoading(false);
      updateQuery(getReports.data.reports);
    },
    onError(err) {
      setLoading(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
    variables: {
      current_page: "1",
      reference: "",
      field: "",
      start_date: "",
      end_date: "",
    },
  });

  const updateQuery = (res) => {
    setPagingInfo({
      current_page: parseInt(res.current_page),
      total: res.total,
      totalPerPage: res.totalPerPage,
    });
    setReports(res.reports);
    setReportsKept(res.reports);
  };

  const handlePageChange = async (val) => {
    const res = await getReports.fetchMore({
      variables: {
        current_page: `${val}`,
        reference: "",
        field: "",
        start_date: "",
        end_date: "",
      },
    });
    updateQuery(res.data.reports);
  };

  const [dismissReport] = useMutation(CancelReportRequest, {
    update(proxy, result) {
      toast.success("Report dismissed!");
      setCancelLoading("");
    },
    onError(err) {
      setCancelLoading("");
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
  });

  const viewReport = (id) => {
    history.push(`/reports/${id}`);
  };
  return (
    <PageSetup title="Reports">
      {loading ? (
        <BoxedComponentLoading />
      ) : (
        <div className="row">
          {reportsKept.length > 0 ? (
            <>
              {reports.map((each) => (
                <div
                  className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3"
                  key={each._id}
                >
                  <div className="border box-shadow">
                    <div className="d-flex align-items-center p-2 border-bottom">
                      <div className="me-2">
                        <img
                          src={
                            each.reported_user?.displayPicture
                              ? each.reported_user.displayPicture
                              : profile
                          }
                          alt=""
                          className="profile-img-sm"
                        />
                      </div>
                      <div>
                        <div className="font18 font-bold">
                          {each.reported_user?.firstName}{" "}
                          {each.reported_user?.lastName}
                        </div>
                        <div className="font-bold text-grey">
                          @{each.reported_user?.username}
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom p-2 font14">
                      {each.note}
                      <br />
                      Report Count: {each.reported_count}
                      <div className=" font-bold">
                        <span className="text-red">
                          <span
                            className="iconify"
                            data-icon="clarity:date-outline-alerted"
                          ></span>
                        </span>
                        <span className="ms-1 font12 text-grey">
                          {DateTime.fromJSDate(
                            new Date(each.createdAt)
                          ).toFormat("dd/MM/yyyy")}
                        </span>
                      </div>
                    </div>
                    {cancelLoading === each._id ? (
                      <div className="spinner-border spinner-border-sm text-red font20 m-2"></div>
                    ) : (
                      <div className="d-flex font20">
                        <div
                          className="border-end w-50 text-center py-2 text-red click"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Dismiss Report"
                          onClick={() => {
                            setCancelLoading(each._id);
                            dismissReport({
                              variables: {
                                report_id: each._id,
                              },
                            }).then((result) => {
                              setReports([
                                ...reportsKept.filter(
                                  (r) => r._id !== each._id
                                ),
                              ]);
                              setReportsKept([
                                ...reportsKept.filter(
                                  (r) => r._id !== each._id
                                ),
                              ]);
                            });
                          }}
                        >
                          <span
                            className="iconify"
                            data-icon="fluent:channel-dismiss-48-filled"
                          ></span>
                        </div>
                        <div
                          className="w-50 text-center click py-2 text-green"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="View Report"
                          onClick={() => viewReport(each._id)}
                        >
                          <span
                            className="iconify"
                            data-icon="carbon:view"
                          ></span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-center">
                {pagingInfo.total && (
                  <Pagination
                    activePage={pagingInfo.current_page}
                    itemsCountPerPage={10}
                    totalItemsCount={pagingInfo.total}
                    pageRangeDisplayed={5}
                    itemClass="page-item"
                    linkClass="page-link"
                    onChange={(e) => handlePageChange(e)}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="text-center">
              <EmptyList title="Report" />
            </div>
          )}
        </div>
      )}
    </PageSetup>
  );
};
