import { gql } from "@apollo/client";

export const GetStaffs = gql`
  query staffs($current_page: String!) {
    staffs(current_page: $current_page)
  }
`;

export const FetchUserRequest = gql`
  query fetchUser(
    $current_page: String
    $reference: String
    $field: String
    $start_date: String
    $end_date: String
  ) {
    fetchUser(
      current_page: $current_page
      reference: $reference
      field: $field
      start_date: $start_date
      end_date: $end_date
    )
  }
`;

export const FetchWaitingListRequest = gql`
  query waitingList(
    $current_page: String
    $start_date: String
    $end_date: String
  ) {
    waitingList(
      current_page: $current_page
      start_date: $start_date
      end_date: $end_date
    )
  }
`;

export const FetchReportsRequest = gql`
  query reports($current_page: String) {
    reports(current_page: $current_page)
  }
`;

export const FetchReportsDetailsRequest = gql`
  query reportDetails($report_id: String) {
    reportDetails(report_id: $report_id)
  }
`;

export const CancelReportRequest = gql`
  mutation cancelReport($report_id: String) {
    cancelReport(report_id: $report_id)
  }
`;

export const FetchUserDetailsRequest = gql`
  query fetchUserDetails($user_id: String) {
    fetchUserDetails(user_id: $user_id)
  }
`;

export const GenerateKeyRequest = gql`
  mutation newGoldenKeys($number: Int, $user: [String]) {
    newGoldenKeys(number: $number, user: $user)
  }
`;

export const FetchKeysRequest = gql`
  query allGoldenKey($current_page: String!) {
    allGoldenKey(current_page: $current_page)
  }
`;

export const revokeKeyRequest = gql`
  mutation revokeGoldenKey($key_id: String) {
    revokeGoldenKey(key_id: $key_id)
  }
`;

export const FetchDashboardRequest = gql`
  query dashboard {
    dashboard
  }
`;

export const ApproveUserRequest = gql`
  mutation approveWaitingList($user_id: [String]) {
    approveWaitingList(user_id: $user_id)
  }
`;

export const DeactivateStaffRequest = gql`
  mutation deactivateStaff($user_id: String) {
    deactivateStaff(user_id: $user_id)
  }
`;

export const ActivateStaffRequest = gql`
  mutation activateStaff($user_id: String) {
    activateStaff(user_id: $user_id)
  }
`;
