import React, { useEffect, useState } from "react";
import * as V from "victory";

export const LineGraph = ({ chartData }) => {
  const newData = [];
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const allCounts = chartData.count;
    const allMonths = chartData.month;
    for (let i = 0; i < allCounts.length; i++) {
      newData.push({
        x: allMonths[i],
        y: allCounts[i],
      });
    }
  };
  return (
    <div style={{ height: "300px" }}>
      <V.VictoryChart
        // theme={V.VictoryTheme.material}
        animate={{ duration: 1000 }}
      >
        <V.VictoryStack colorScale={"blue"}>
          <V.VictoryArea
            data={newData}
            interpolation={"basis"}
            style={{ labels: { fontSize: 20, fill: "white" } }}
            // width={800}
            height={400}
            standalone={false}
          />
        </V.VictoryStack>
      </V.VictoryChart>
    </div>
  );
};
