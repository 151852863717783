import "./App.scss";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Dashboard } from "./pages/dashboard";
import { Login } from "./Auth/Login";
import { WaitingList } from "./pages/WaitingList";
import { Team } from "./pages/Team";
import { Settings } from "./pages/Settings";
import { Users } from "./pages/Users/userList";
import { UserDetails } from "./pages/Users/user-details";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import "react-toastify/dist/ReactToastify.css";
import { GoldenKeys } from "./pages/GoldenKeys";
import { ReportsPage } from "./pages/Reports/ReportsPage";
import { ReportDetailsPage } from "./pages/Reports/ReportDetails";
import { PrivacyPolicy } from "./pages/Docs/PrivacyPolicy";
import { Cookies } from "./pages/Docs/Cookies";
import { DataProtection } from "./pages/Docs/DataProtection";
import { TermsConditions } from "./pages/Docs/TermsConditions";

// Initialize apollo and set authorization token
const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_BASE_URL}`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  credentials: "same-origin",
});

function App() {
  return (
    <>
      <ApolloProvider client={client}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          toastClassName="px-2 shadow-sm rounded-md"
        />
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </ApolloProvider>
    </>
  );
}
function Main() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/reports" component={ReportsPage} />
        <Route exact path="/reports/:reportId" component={ReportDetailsPage} />
        <Route exact path="/team" component={Team} />
        <Route exact path="/users" component={Users} />
        <Route exact path="/users/:userId" component={UserDetails} />
        <Route exact path="/keys" component={GoldenKeys} />
        <Route exact path="/waiting-list" component={WaitingList} />
        <Route exact path="/settings" component={Settings} />
        <Route exact path="/settings" component={Settings} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/cookies" component={Cookies} />
        <Route exact path="/data-protection" component={DataProtection} />
        <Route exact path="/terms-conditions" component={TermsConditions} />
        <Redirect to="/" />
      </Switch>
    </>
  );
}

export default App;
