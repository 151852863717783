import React from "react";

export const Footer = () => {
  const current_date = new Date().getFullYear();
  return (
    <footer className="py-3 px-4 d-flex justify-content-between flex-wrap align-items-center font12">
      <div>©{current_date} Fro-Positive Community</div>
      <div className="text-grey">
        A social networking platform solely for celebrating the diverse black
        community.
      </div>
    </footer>
  );
};
