import React from "react";

export const DataProtection = () => {
  return (
    <div className="container mt-5">
      <p>
        <h4>GENERAL DATA PROTECTION REGULATIONS</h4>
      </p>
      <p>
        <strong>
          1. GENERAL DATA PROTECTION REGULATIONS (GDPR)
          <span class="Apple-converted-space">&nbsp;</span>
        </strong>
      </p>
      <p>
        This GDPR policy ensures FroClub: -{" "}
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <ul>
        <ul>
          <li>Complies with data protection law and follows good practice;</li>
          <li>
            Protects the rights of users;
            <span class="Apple-converted-space">&nbsp;</span>
          </li>
          <li>
            Are open about how we store and process individuals&rsquo; data;{" "}
            <span class="Apple-converted-space">&nbsp;</span>
          </li>
          <li>
            Protect ourselves from data protection risks such as breaches of
            confidentiality, failure to offer choice and reputational damage.
            <span class="Apple-converted-space">&nbsp;</span>
          </li>
        </ul>
      </ul>
      <p>&nbsp;</p>
      <p>
        <strong>
          2. PRINCIPLES OF GDPR<span class="Apple-converted-space">&nbsp;</span>
        </strong>
      </p>
      <p>
        Under the GDPR, the data protection principles set out the main
        responsibilities for us. Article 5 of the GDPR requires that personal
        data shall be: -
      </p>
      <p>&nbsp;</p>
      <p>
        (a) Processed lawfully, fairly and in a transparent manner in relation
        to individuals;<span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        (b) Collected for specified, explicit and legitimate purposes and not
        further processed in a manner that is incompatible with those purposes;
      </p>
      <p>
        (c) Adequate, relevant and limited to what is necessary in relation to
        the purposes for which they are processed;
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        (d) Accurate and, where necessary, kept up to date; every reasonable
        step must be taken to ensure that personal data that are inaccurate,
        having regard to the purposes for which they are processed, are erased
        or rectified without delay;
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        (e) Kept in a form which permits identification of data subjects for no
        longer than is necessary for the purposes for which the personal data
        are processed; (f) Processed in a manner that ensures appropriate
        security of the personal data, including protection against unauthorized
        or unlawful processing and against accidental loss, destruction or
        damage.<span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          3. LAWFULNESS OF PROCESSING CONDITIONS
          <span class="Apple-converted-space">&nbsp;</span>
        </strong>
      </p>
      <p>
        Under the GDPR, there is requirement to have a valid lawful basis in
        order to process personal data. There are six available lawful bases for
        processing set out in Article 6 of the GDPR: -
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        (a) Consent: the data subject has given clear unambiguous consent for
        their personal data to be processed for a specific purpose;
      </p>
      <p>
        (b) Contract: processing is necessary for the performance of a contract
        with the data subject or to take steps to enter into a contract;
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        (c) Legal obligation: processing is necessary for compliance with a
        legal obligation;
      </p>
      <p>
        (d) Vital interests: processing is necessary to protect the vital
        interests of a data subject or another individual;
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        (e) Public task: processing is necessary for the performance of a task
        carried out in the public interest or in the exercise of official
        authority vested in the controller;
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        (f) Legitimate interests: processing is necessary for the purposes of
        legitimate interests pursued by the controller or a third party, except
        where such interests are overridden by the interests, rights or freedoms
        of the data subject.<span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>4. YOUR RIGHTS UNDER THE GDPR</strong>
      </p>
      <p>
        The GDPR provides the following rights for users: -
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        1. The right to be informed;
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        2. The right of access;<span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        3. The right to rectification;
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        4. The right to erasure;
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        5. The right to restrict processing;
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        6. The right to data portability;
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        7. The right to object;<span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>8. Rights in relation to automated decision making and profiling.</p>
      <p>
        5. CHANGES TO OUR GDRP POLICY
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        FroClub reserves the right to change this GDPR policy at any time. If
        FroClub decides to change this GDPR Policy, we will post those changes
        on our app so our users are always aware of what information we collect,
        use and disclose. In all cases, your continued use of our app after any
        change to this GDPR Policy will constitute your acceptance of such
        change.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          6. ACCEPTANCE<span class="Apple-converted-space">&nbsp;</span>
        </strong>
      </p>
      <p>
        By accessing our app and placing an order you have willingly accepted
        the terms of this GDPR Policy.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
    </div>
  );
};
