import React, { useState } from "react";
import { PageSetup } from "../../components/PageSetup";
import profile from "../../assets/img/user.png";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { FetchUserRequest } from "../../services/users";
import { handleGeneralErrors } from "../../utils/handleGeneralErrors";
import Pagination from "react-js-pagination";
import { TableLoading } from "../../components/LoadingComponents/TableLoading";
import { EmptyList } from "../../components/EmptyList";
import { DateTime } from "luxon";
import { GenerateKey } from "../../components/generateKey";

export const Users = () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [usersKept, setUsersKept] = useState([]);
  const [pagingInfo, setPagingInfo] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const getUserList = useQuery(FetchUserRequest, {
    onCompleted() {
      setLoading(false);
      updateQuery(getUserList.data.fetchUser);
    },
    onError(err) {
      setLoading(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
    variables: {
      current_page: "1",
      reference: "",
      field: "",
      start_date: "",
      end_date: "",
    },
  });

  const updateQuery = (res) => {
    setPagingInfo({
      current_page: parseInt(res.current_page),
      total: res.total,
      totalPerPage: res.totalPerPage,
    });
    setUsers(res.user);
    setUsersKept(res.user);
  };

  const handlePageChange = async (val) => {
    const res = await getUserList.fetchMore({
      variables: {
        current_page: `${val}`,
        reference: "",
        field: "",
        start_date: "",
        end_date: "",
      },
    });
    updateQuery(res.data.fetchUser);
  };

  const searchUser = (event) => {
    const searchVal = event.target.value;
    if (searchVal) {
      let result = usersKept.filter((val) => {
        return (
          val.email
            .toLowerCase()
            .trim()
            .includes(searchVal.toLowerCase().toString().trim()) ||
          val.firstName
            .toLowerCase()
            .trim()
            .includes(searchVal.toLowerCase().toString().trim()) ||
          val.lastName
            .toLowerCase()
            .trim()
            .includes(searchVal.toLowerCase().toString().trim()) ||
          val.username
            .toLowerCase()
            .trim()
            .includes(searchVal.toLowerCase().toString().trim())
        );
      });
      setUsers(result);
    } else {
      setUsers(usersKept);
    }
  };

  const allUsersSelected = () => {
    const usersId = users.map((u) => u._id);
    return (
      usersId.every((_id) => selectedUsers.includes(_id)) ||
      selectedUsers.length === users.length
    );
  };

  const markAll = () => {
    const usersId = users.map((u) => u._id);
    !allUsersSelected() ? setSelectedUsers(usersId) : setSelectedUsers([]);
  };

  const isUserSelected = (userId) => {
    return selectedUsers.includes(userId);
  };

  const toggleUserSelection = (user, $event) => {
    !selectedUsers.includes(user._id)
      ? setSelectedUsers([...selectedUsers, user._id])
      : setSelectedUsers(selectedUsers.filter((uId) => uId !== user._id));
  };

  return (
    <PageSetup title="Users">
      {usersKept.length > 0 && (
        <div className="d-flex justify-content-between">
          <div className="input-container mb-3">
            <div className="input-icon">
              <span
                className="iconify"
                data-icon="bx:bx-search"
                data-inline="false"
              ></span>
            </div>
            <input
              type="text"
              placeholder="Search by Name"
              className="form-control"
              onChange={searchUser}
            />
          </div>
          <div>
            <button
              className="btn btn-blue"
              data-bs-toggle="modal"
              data-bs-target="#generateKeyModal"
            >
              Generate Key
            </button>
          </div>
        </div>
      )}
      <GenerateKey
        selectedUsers={selectedUsers}
        onSuccess={() => {
          setSelectedUsers([]);
        }}
      />
      <section className="mb-4 rounded box-shadow bg-white p-4">
        <div className="table-responsive">
          {loading ? (
            <TableLoading />
          ) : (
            <>
              {usersKept.length > 0 ? (
                <table className="table caption-top text-nowrap">
                  <caption className="font-bold font20">Active Users</caption>
                  <thead>
                    <tr>
                      <th scope="col">
                        <input
                          type="checkbox"
                          checked={users.length === selectedUsers.length}
                          onChange={markAll}
                        />
                      </th>
                      <th scope="col" className="w-250px">
                        User Profile
                      </th>
                      <th scope="col">Email</th>
                      <th scope="col">Date Created</th>
                      <th scope="col">Age Range</th>
                      <th scope="col">Sex</th>
                      <th scope="col">Role</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((each) => (
                      <tr key={each._id}>
                        <td className="w-50px">
                          <input
                            type="checkbox"
                            checked={isUserSelected(each._id)}
                            onChange={(e) => toggleUserSelection(each, e)}
                          />
                        </td>
                        <td className="w-250px">
                          <div className="d-flex align-items-center">
                            <div className="me-2">
                              <img
                                src={
                                  each.displayPicture
                                    ? each.displayPicture
                                    : profile
                                }
                                alt=""
                                className="profile-img-sm"
                              />
                            </div>
                            <div>
                              <div className="font18 font-bold">
                                {each.firstName}
                                {each.lastName}
                              </div>
                              <div className="font-bold text-grey">
                                @{each.username}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="w-200px">{each.email}</td>
                        <td>
                          {DateTime.fromJSDate(
                            new Date(each.createdAt)
                          ).toFormat("dd/MM/yyyy h:mm a")}
                        </td>
                        <td>{each.ageRange}yrs</td>
                        <td>Female</td>
                        <td>{each.isAdmin ? "Admin" : "Member"}</td>
                        <td>{each.status}</td>
                        <td>
                          <u
                            className="font-bold click text-green"
                            onClick={() => {
                              history.push(`/users/${each._id}`);
                            }}
                          >
                            View
                          </u>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-center">
                  <EmptyList title="user" />
                </div>
              )}
            </>
          )}

          <div className="d-flex justify-content-center">
            {pagingInfo.total && (
              <Pagination
                activePage={pagingInfo.current_page}
                itemsCountPerPage={10}
                totalItemsCount={pagingInfo.total}
                pageRangeDisplayed={5}
                itemClass="page-item"
                linkClass="page-link"
                onChange={(e) => handlePageChange(e)}
              />
            )}
          </div>
        </div>
      </section>
    </PageSetup>
  );
};
