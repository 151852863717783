import React, { useState } from "react";
import { PageSetup } from "../../components/PageSetup";
import follower from "../../assets/img/followers.svg";
import following from "../../assets/img/following.svg";
import profile from "../../assets/img/user.png";
import key from "../../assets/img/key.svg";
import post from "../../assets/img/post.svg";
import { PieChart } from "../../components/PieChart";
import { useHistory, useParams } from "react-router";
import { FetchUserDetailsRequest } from "../../services/users";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { handleGeneralErrors } from "../../utils/handleGeneralErrors";
import { WideComponentLoading } from "../../components/LoadingComponents/WideComponentLoading";
import { DateTime } from "luxon";
import admin from "../../assets/img/admin.png";

const totalCards = (icon, label, number, position) => {
  return (
    <div className={`bg-grey br-5 px-3 py-2 w-50 totalcard ${position}`}>
      <div className="d-flex">
        <div className="me-2">
          <img src={icon} alt="" className="totalcard-img" />
        </div>
        <div className="text-dark-brown">
          <p className="text-dark-brown total-label mb-0">{label}</p>
          <p className="text-dark-brown total-number font-bold mb-0">
            {number}
          </p>
        </div>
      </div>
    </div>
  );
};
const progressBar = (icon, progress, color) => {
  return (
    <div className="d-flex align-items-center">
      <div className="img w-10">
        <span className="iconify" data-icon={icon}></span>
      </div>
      <div class="progress w-90">
        <div
          class={`progress-bar ${color}`}
          role="progressbar"
          style={{ width: `${progress}%` }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {progress}%
        </div>
      </div>
    </div>
  );
};
export const UserDetails = () => {
  const [usersInfo, setUsersInfo] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { userId } = useParams();

  const getUserDetail = useQuery(FetchUserDetailsRequest, {
    onCompleted() {
      setLoading(false);
      setUsersInfo(getUserDetail.data.fetchUserDetails);
    },
    onError(err) {
      setLoading(false);
      if (err.graphQLErrors) {
        handleGeneralErrors(err.graphQLErrors);
      } else {
        toast.error("Sorry an error occurred!");
      }
    },
    variables: {
      user_id: userId,
    },
  });
  const goBack = () => {
    history.goBack();
  };
  return (
    <PageSetup title="User Profile">
      <div>
        <span className="click font-bold" onClick={goBack}>
          <span className="me-1">
            <span class="iconify" data-icon="bx:bx-arrow-back"></span>
          </span>
          Back
        </span>
      </div>
      {loading ? (
        <>
          <section>
            <WideComponentLoading />
          </section>
          <section className="row">
            {[...Array(4)].map((e, index) => (
              <div className="col-md-6 col-12 mb-3" key={index}>
                <WideComponentLoading />
              </div>
            ))}
          </section>
        </>
      ) : (
        <>
          {usersInfo.user ? (
            <section className="user-profile-page">
              <section className="position-relative">
                <div className="banner p-3">
                  <div className="d-flex justify-content-between mb-3">
                    <div>
                      {usersInfo.user.status === "active" ? (
                        <button className="btn btn-green btn-sm">Active</button>
                      ) : (
                        <button className="btn btn-red btn-sm">Inactive</button>
                      )}
                    </div>
                    {usersInfo.user.isAdmin && (
                      <div>
                        <img src={admin} style={{ width: "50px" }} />
                      </div>
                    )}
                  </div>
                </div>

                <div className="profile">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <img
                        src={
                          usersInfo.user.displayPicture
                            ? usersInfo.user.displayPicture
                            : profile
                        }
                        alt=""
                        className="profile-img"
                      />
                    </div>
                    <div>
                      <h5 className=" font-bold">
                        {usersInfo.user.firstName} {usersInfo.user.lastName}
                      </h5>
                      <p className="text-dark-brown font18 font-bold mb-1">
                        @{usersInfo.user.username}
                      </p>
                      <p className="text-dark-brown mb-0">
                        {usersInfo.user.email}
                      </p>
                      <p className="text-dark-brown mb-0">
                        {usersInfo.user.ageRange} yrs.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <div className="text-end font-bold mb-0">
                <span className="text-grey font14">Date Joined:</span>{" "}
                <span className="text-dark-brown font18">
                  {DateTime.fromJSDate(
                    new Date(usersInfo.user.createdAt)
                  ).toLocaleString(DateTime.DATE_MED)}
                </span>
              </div>
              <section className="row">
                <div className="col-lg-6 col-md-6 col-12 my-2">
                  <div className="bg-white br-5 px-4 py-3 box-shadow">
                    <p className="font-bold">Profile Statistics</p>
                    {progressBar("ic:baseline-report-problem", 25, "bg-red")}
                    {progressBar("ic:baseline-report-problem", 80, "bg-green")}
                    {progressBar("ic:baseline-report-problem", 25, "bg-red")}
                    {progressBar("ic:baseline-report-problem", 80, "bg-green")}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 my-2">
                  <div className="bg-white br-5 px-4 py-3 box-shadow">
                    <div className="d-flex mb-4">
                      {totalCards(
                        follower,
                        "Followers",
                        usersInfo.all_follower_count,
                        "me-4"
                      )}
                      {totalCards(
                        following,
                        "Following",
                        usersInfo.all_following_count,
                        "me-0"
                      )}
                    </div>

                    <div className="d-flex mb-4">
                      {totalCards(
                        post,
                        "All Posts",
                        usersInfo.all_post_count,
                        "me-4"
                      )}
                      {totalCards(
                        key,
                        "Golden Key",
                        usersInfo.golden_key_count,
                        "me-0"
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-12 my-2">
                  <div className="bg-white br-5 px-4 py-3 box-shadow">
                    <PieChart />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 my-2">
                  <div className="bg-white br-5 px-4 py-3 box-shadow">
                    <PieChart />
                  </div>
                </div>
              </section>
            </section>
          ) : (
            <section>Empty</section>
          )}
        </>
      )}
    </PageSetup>
  );
};
